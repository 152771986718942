import { createSlice } from "@reduxjs/toolkit";

const adminActivitySlice = createSlice({
  name: "adminActivity",
  initialState: {
  
     adminselectedname : "",
     adminToolsData:[],
  
  },
  reducers: {
    setadminActivityList: {
      reducer(state, action) {
        state.adminselectedname = action.payload;
      },
    },
    setadminToolsData: {
      reducer(state, action) {
        state.adminToolsData = action.payload;
      },
    },
  },
});

export const {
  setadminActivityList,setadminToolsData
} = adminActivitySlice.actions;

export default adminActivitySlice.reducer;
