import React, { useEffect } from "react";

const AccessDenied = () => {
  return (
    <div>
      <div className="content text-center">
        <div className="a-403">403</div>
        <div className="a-forbidden">Forbidden</div>
        <div>Access to this resource on the server is denied!</div>
      </div>
    </div>
  );
};

export default AccessDenied;
