import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const CloudUserTotal = createAsyncThunk(
    "get/Ctotal",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDD/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)
export const CloudUserPending = createAsyncThunk(
    "get/Cpending",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDDPending/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const CloudUserContinue = createAsyncThunk(
    "get/CContinue",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDDContinue/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)
export const CloudUserRemove = createAsyncThunk(
    "get/CRemove",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageDDRemove/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const onpremiseTotal = createAsyncThunk(
    "get/PTotal",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDD/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const onpremisePending = createAsyncThunk(
    "get/PPending",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDPending/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const onpremiseContinue = createAsyncThunk(
    "get/PContinue",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDContinue/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const onpremiseRemove = createAsyncThunk(
    "get/PRemove",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDRemove/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)
//completed page 
export const CompletedCContinue = createAsyncThunk(
    "get/ComContinue",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageDDCloudContinue/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)
export const CompletedCRemove = createAsyncThunk(
    "get/ComRemove",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageDDCloudRemove/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const CompletedPermiseContinue = createAsyncThunk(
    "get/ComPerContinue",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageOnPremiseDD/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)

export const CompletedPermiseRemove = createAsyncThunk(
    "get/ComPerRemove",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const { appname } = props;
            const response = await fetch(`/api/v1/UserAccessRest/reviewCompletePageOnPremiseDDRemove/${appname}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
)
export const ComplianceTrendAPI = createAsyncThunk(
    "post/ComplianceTrendAPI",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/UserAccessRest/CISOGraphOne`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);




export const reviewslice = createSlice({
    name: 'review',
    initialState: {
        CTotalData: [],
        CPendingData: [],
        CContinueData: [],
        CRemovedData: [],
        PTotalData: [],
        PPendingData: [],
        PContinueData: [],
        PRemovedData: [],
        CompltedCContinueData: [],
        CompltedCRemoveData: [],
        CompltedPContinueData: [],
        CompltedPRemoveData: [],
        CompletedLoading: false,
        CTotalloading: false,
        complianceGraphData:[],
        complianceLoading: false,
        error: '',
    },

    reducers: {
        reviewSlicereset: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            //CTtoal
            .addCase(CloudUserTotal.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(CloudUserTotal.fulfilled, (state, action) => {
                state.CTotalData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(CloudUserTotal.rejected, (state, action) => {
                state.CTotalloading = false;
                state.CTotalData = [];
                state.error = "something went wrong";

            })
            //CPending
            .addCase(CloudUserPending.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(CloudUserPending.fulfilled, (state, action) => {
                state.CPendingData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(CloudUserPending.rejected, (state, action) => {
                state.CTotalloading = false;
                state.CPendingData = [];
                state.error = "something went wrong";

            })
            //CContinue
            .addCase(CloudUserContinue.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(CloudUserContinue.fulfilled, (state, action) => {
                state.CContinueData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(CloudUserContinue.rejected, (state, action) => {
                state.CTotalloading = false;
                state.CContinueData = [];
                state.error = "something went wrong";

            })

            //CRemove
            .addCase(CloudUserRemove.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(CloudUserRemove.fulfilled, (state, action) => {
                state.CRemovedData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(CloudUserRemove.rejected, (state, action) => {
                state.CTotalloading = false;
                state.CRemovedData = [];
                state.error = "something went wrong";

            })
            //PTotal
            .addCase(onpremiseTotal.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(onpremiseTotal.fulfilled, (state, action) => {
                state.PTotalData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(onpremiseTotal.rejected, (state, action) => {
                state.CTotalloading = false;
                state.PTotalData = [];
                state.error = "something went wrong";

            })
            //PPending
            .addCase(onpremisePending.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(onpremisePending.fulfilled, (state, action) => {
                state.PPendingData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(onpremisePending.rejected, (state, action) => {
                state.CTotalloading = false;
                state.PPendingData = [];
                state.error = "something went wrong";

            })
            //PContinue
            .addCase(onpremiseContinue.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(onpremiseContinue.fulfilled, (state, action) => {
                state.PContinueData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(onpremiseContinue.rejected, (state, action) => {
                state.CTotalloading = false;
                state.PContinueData = [];
                state.error = "something went wrong";

            })
            //PRemove
            .addCase(onpremiseRemove.pending, (state, action) => {
                state.CTotalloading = true;
                state.error = "";
            })
            .addCase(onpremiseRemove.fulfilled, (state, action) => {
                state.PRemovedData = action.payload;
                state.CTotalloading = false;
            })
            .addCase(onpremiseRemove.rejected, (state, action) => {
                state.CTotalloading = false;
                state.PRemovedData = [];
                state.error = "something went wrong";

            })
            //COMContinue
            .addCase(CompletedCContinue.pending, (state, action) => {
                state.CompletedLoading = true;
                state.error = "";
            })
            .addCase(CompletedCContinue.fulfilled, (state, action) => {
                state.CompltedCContinueData = action.payload;
                state.CompletedLoading = false;
            })
            .addCase(CompletedCContinue.rejected, (state, action) => {
                state.CompletedLoading = false;
                state.CompltedCContinueData = [];
                state.error = "something went wrong";

            })
            //COMemove
            .addCase(CompletedCRemove.pending, (state, action) => {
                state.CompletedLoading = true;
                state.error = "";
            })
            .addCase(CompletedCRemove.fulfilled, (state, action) => {
                state.CompltedCRemoveData = action.payload;
                state.CompletedLoading = false;
            })
            .addCase(CompletedCRemove.rejected, (state, action) => {
                state.CompletedLoading = false;
                state.CompltedCRemoveData = [];
                state.error = "something went wrong";

            })

            //COMPerContinue
            .addCase(CompletedPermiseContinue.pending, (state, action) => {
                state.CompletedLoading = true;
                state.error = "";
            })
            .addCase(CompletedPermiseContinue.fulfilled, (state, action) => {
                state.CompltedPContinueData = action.payload;
                state.CompletedLoading = false;
            })
            .addCase(CompletedPermiseContinue.rejected, (state, action) => {
                state.CompletedLoading = false;
                state.CompltedPContinueData = [];
                state.error = "something went wrong";

            })
            //COMPerRemove
            .addCase(CompletedPermiseRemove.pending, (state, action) => {
                state.CompletedLoading = true;
                state.error = "";
            })
            .addCase(CompletedPermiseRemove.fulfilled, (state, action) => {
                state.CompltedPRemoveData = action.payload;
                state.CompletedLoading = false;
            })
            .addCase(CompletedPermiseRemove.rejected, (state, action) => {
                state.CompletedLoading = false;
                state.CompltedPRemoveData = [];
                state.error = "something went wrong";

            })
             //ComplianceTrendAPI
             .addCase(ComplianceTrendAPI.pending, (state, action) => {
                state.complianceLoading = true;
              })
              .addCase(ComplianceTrendAPI.fulfilled, (state, action) => {
                state.complianceGraphData = action.payload;
                state.complianceLoading = false;
              })
              .addCase(ComplianceTrendAPI.rejected, (state, action) => {
                state.complianceLoading = false;
              })
    },
});

export const { reviewSlicereset } = reviewslice.actions;
export default reviewslice.reducer;