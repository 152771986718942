import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/common.scss";
// import "./assets/css/style.scss"
import "./assets/css/internal.scss";
import "./assets/css/bootstrap_extended.scss";
import "./assets/css/common-overrides.scss";
import "./assets/css/observability.scss";
import 'typeface-montserrat';

// import "./assets/css/calender-custom.scss"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { authRoutes, routes } from "./app/routes";
import { store } from "./app/store";
import { Provider } from "react-redux";
import ContainerLoader from "./app/common-components/ContainerLoader";
import PrivateRoute from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccessDenied from "./app/common-components/AccessDenied";
import NotFound from "./app/common-components/NotFound";
import 'react-calendar/dist/Calendar.css';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
function App() {
  const { loggedUser, pageLoading } = useSelector((state) => state.common);
  const { userName, userRole } = loggedUser;
  const CisoPageAccessRole = ['Ciso', 'Operation']
  const isAccessDenied = (routepath) => {
    const rolePaths = {
      Application_manager: ["configuration/administration/ldapConfig", "configuration/administration/smtpConfig", "configuration/administration/licenseConfig", "configuration/administration/userManagement", "configuration/administration/loggingConfig"],
    };
  
    return rolePaths[userRole]?.some(path => routepath.includes(path));
  };
  const renderRoutes = (allRoutes) => {
    return allRoutes.map((route) =>
      route.childNavs ? (
        renderRoutes(route.childNavs)
      ) : (
        <Route
          key={route.key}
          path={route.path}
          element={ userRole == "Ciso" && route.path.includes("configuration/administration") ? (
            <Navigate to="/access-denied" replace />
          ) : !CisoPageAccessRole.includes(userRole) && route.path.includes("gnc/CISOInsights") || isAccessDenied(route.path) ? <Navigate to="/access-denied" replace /> : route.component}
        />
      )
    );
  };
  return (
    <Provider store={store}>
      <ToastContainer />
      <Suspense fallback={<ContainerLoader />}>
        <Router>
          <Routes>
            {authRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={route.component}
              />
            ))}
            <Route path="/" element={<PrivateRoute />}>
              {renderRoutes(routes)}
              <Route path="/access-denied" element={<AccessDenied/>}></Route>
            </Route>
           <Route path="*" element={<NotFound/>}/>
          </Routes>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
