import { createSlice } from "@reduxjs/toolkit";

const piiUserBarSlice = createSlice({
    name: 'Name',
    initialState: {
        piiUserName: '',
        piiType:'',
        cisoChange:'Vm',
    },
    reducers: {
      setPiiUserNameList: (state, action) => {
        state.piiUserName = action.payload;
      },
      setPiiTypeList:(state, action) => {
        state.piiType = action.payload;
      },
      setcisotype:(state, action) => {
        state.cisoChange = action.payload;
      }
    }
  });
  
  export const { setPiiUserNameList,setPiiTypeList,setcisotype } = piiUserBarSlice.actions;
  export default piiUserBarSlice.reducer;
