import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MainLoader from "./MainLoader";
import {setRedirectPath} from "../../app/utilities/helpers";

const NotFound = () => {
  const [showPage, setShowPage] = useState(false);
  const { userName, userRole } = useSelector(
    (state) => state.common.loggedUser
  );
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = get(location, "pathname", "/");
  console.log("user", userName, userRole,);
  const userLoggin = sessionStorage.getItem("isLoggedin")
  console.log("userLoggin",userLoggin)

  useEffect(() => {
    setRedirectPath();
    if(userLoggin === "true"){
      setShowPage(true);
    }
    else{
      navigate("/auth");
    }
  }, [userLoggin]);

  return (
    <>
      {showPage ? (
        <>
          <h1>Whitelabel Error Page</h1>
          <p>
            This application has no explicit mapping for /error, so you are
            seeing this as a fallback.
          </p>
          <h4 id="created">{new Date().toLocaleString()}</h4>
          <div>There was an unexpected error (type=Not Found, status=404).</div>
          <div>No message available</div>
        </>
      ) : (
        <MainLoader />
      )}
    </>
  );
};

export default NotFound;
