const storage = [
    "isConfigClicked",
    "toolsCurrentPage",
    "CmdbPageNo",
    "isClicked",
];

export const storageRemove = () =>{
    storage.map((item) =>{
        localStorage.removeItem(item);
    });
}