import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const userAccessReviewTable = createAsyncThunk(
    "post/getData",
    async (props) => {
        const { pageNumber, itemsPerPage, payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`/api/v1/UserAccessRest/getApplicationUserAccessReviewWithSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;
        })
            .catch(err => {
                return err;
            })
        return res;

    });

        
    export const userAccessReviewTableCount = createAsyncThunk(
        "post/getDataCount",
        async (props) => {
            const { pageNumber, itemsPerPage, payload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const res = await fetch(`/api/v1/UserAccessRest/getApplicationUserAccessReviewListCountWithSearchAndPagination`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                },
                body: JSON.stringify(payload)
            }).then(res => {
                const result = res.json();
                return result;
            })
                .catch(err => {
                    return err;
                })
            return res;
    
        });


    export const UserAccessReviewSlice = createSlice({
        name: 'UserAccessReviewSlice',
        initialState: {
            userAccessReviewTableData:[],
            uARTableLoading:false,
            userAccessReviewPagination:[],
            uARTableCountLoading:false,

        },

        
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(userAccessReviewTable.pending, (state, action) => {
                    state.uARTableLoading = true;
                })
                .addCase(userAccessReviewTable.fulfilled, (state, action) => {
                    state.userAccessReviewTableData = action.payload;
                    state.uARTableLoading = false;
                })
                .addCase(userAccessReviewTable.rejected, (state, action) => {
                    state.uARTableLoading = false;
                })


                .addCase(userAccessReviewTableCount.pending, (state, action) => {
                    state.uARTableCountLoading = true;
                })
                .addCase(userAccessReviewTableCount.fulfilled, (state, action) => {
                    state.userAccessReviewPagination = action.payload;
                    state.uARTableCountLoading = false;
                })
                .addCase(userAccessReviewTableCount.rejected, (state, action) => {
                    state.uARTableCountLoading = false;
                });

            },

    })
    export default UserAccessReviewSlice.reducer;