import React, { useState, useEffect } from "react";
import {
  logoSmall,
  notification,
  A360,
  A360_Content,
  user,
  logout,
} from "../../assets/images/index";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { pageTitleMapper, getPageTitle } from "../utilities/constants";
import { get } from "lodash";
import { routes } from "../routes";
import _, { mapValues } from "lodash";
// import { UserRoleForNavBar } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "../commonSlice";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { storageRemove } from "./Storage";
import BreadCrumb from "./BreadCrumb";
import Cookies from "universal-cookie";

const Container = () => {
  const [childMenuState, setChildMenuState] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const { userName, userRole } = useSelector(
    (state) => state.common.loggedUser
  );

  // UserRoleForNavBar(userRole)
  const navigate = useNavigate();

  // const pathName = get(location, "pathname", "");
  const pathName =
    get(location, "pathname", "") === "/gnc/ciso-view/Vulnerability" &&
    userRole !== "Ciso"
      ? ""
      : get(location, "pathname", "");

  const onNavigate = (e, url) => {
    e.preventDefault();
    dispatch(setPageLoading(true));
    navigate(url);
  };
  const logOut = (e) => {
    e.preventDefault();
    var newURL =
      pathName === "/gnc/ciso-view/Vulnerability"
        ? "/gnc/Vulnerability"
        : pathName;
    sessionStorage.setItem("lastVisitedURL", newURL);
    sessionStorage.setItem("lastVistedURL", newURL);
    sessionStorage.setItem("isLoggedin", false);
    fetch("/logout", {
      method: "GET",
      redirect: "manual",
    })
      .then((res) => {
        navigate("/auth");
      })
      .catch((err) => {});
  };

  const handleChildMenuState = (path, value) => {
    setChildMenuState((childMenuState) => {
      const resetMenuState = mapValues({ ...childMenuState }, () => false);
      return { ...resetMenuState, [path]: value };
    });
  };
  
  
  return (
    <div className="wrapper_jr">
      <header>
        <div className="headerwrap">
          <div className="header-logo">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip className="logoTooltip">
                <img className="logoTooltipImg" src={A360_Content} alt="Advisor 360" />
              </Tooltip>
            }
          >
             <a href="#">
              <img src={A360} alt="Advisor 360" />
            </a>
          </OverlayTrigger>
           
          </div>
          <div className="nav small">
            <ul>
              {routes.map((route) => (
                <li
                  className={`dropdown-btn ${
                    pathName.includes(route?.path?.split("/")[0]) && "active"
                  }`}
                >
                  <a
                    href={route.iconNavigation ? `/${route.path}` : undefined}
                    onClick={(e) =>
                      route.iconNavigation && onNavigate(e, route.path)
                    }
                    className={`${
                      route?.toolTipCustomClass
                        ? route?.toolTipCustomClass
                        : "hovertip"
                    }`}
                    data-tip={route.name}
                  >
                    <img src={route.icon} alt={route.name} />
                  </a>
                  <div className="dropdown-container">
                    {route?.childNavs?.map((firstChildNav) => {
                      // Add this line to log the object
                      if (
                        firstChildNav.name === "Ciso Insights" &&
                        userRole !== "Ciso" &&
                        userRole !== "Operation"
                      ) {
                        return null; // return null to skip rendering this component
                      }

                      return (
                        !firstChildNav.isNotSideBarNavigation && (
                          <>
                            <a
                              href={
                                !firstChildNav.navigationDisable
                                  ? `/${firstChildNav?.path}`
                                  : undefined
                              }
                              onClick={(e) =>
                                {!firstChildNav.navigationDisable &&
                                onNavigate(e, firstChildNav?.path), storageRemove()}
                              }
                              onMouseOver={() =>
                                handleChildMenuState(firstChildNav.path, true)
                              }
                              onMouseLeave={() =>
                                handleChildMenuState(firstChildNav.path, false)
                              }
                              className={firstChildNav.navigationDisableNotAllowed ? 'not-allowed' : ''}
                            >
                              {firstChildNav.text}
                            </a>
                            {/* Second Level childNavs */}
                            {firstChildNav?.childNavs &&
                              firstChildNav.name !== "Compliance" && (
                                <div
                                  className={`dropdown-container_child ${
                                    firstChildNav.customDropContainerClass &&
                                    firstChildNav.customDropContainerClass
                                  } ${
                                    childMenuState[firstChildNav.path] &&
                                    "active"
                                  }`}
                                  onMouseOver={() =>
                                    handleChildMenuState(
                                      firstChildNav.path,
                                      true
                                    )
                                  }
                                  onMouseLeave={() =>
                                    handleChildMenuState(
                                      firstChildNav.path,
                                      false
                                    )
                                  }
                                >
                                  {firstChildNav?.childNavs?.map(
                                    (secondChildNav) => {
                                      if (
                                        secondChildNav.name ===
                                          "CISO Insights" &&
                                        userRole !== "Ciso" &&
                                        userRole !== "Operation"
                                      ) {
                                        return null;
                                      }
                                      return (
                                        !secondChildNav.isNotSideBarNavigation && (
                                          <>
                                            <a
                                              key={secondChildNav.path}
                                              href={
                                                !secondChildNav.navigationDisable
                                                  ? `/${secondChildNav?.path}`
                                                  : undefined
                                              }
                                              onClick={(e) =>
                                                {!secondChildNav.navigationDisable &&
                                                onNavigate(
                                                  e,
                                                  secondChildNav?.path
                                                ),storageRemove()}
                                              }
                                              className={secondChildNav.navigationDisableNotAllowed ? 'not-allowed' : ''}
                                            >
                                              {secondChildNav.text}
                                            </a>

                                            {
                                              <div
                                                className={`dropdown-container_childthird ${
                                                  secondChildNav.customDropContainerClass &&
                                                  secondChildNav.customDropContainerClass
                                                } ${
                                                  childMenuState[secondChildNav.path] && "active"
                                                }`}
                                                onMouseOver={() =>
                                                  handleChildMenuState(
                                                    secondChildNav.path, true,                                                 
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  handleChildMenuState(
                                                    secondChildNav.path,
                                                    false,
                                                  )
                                                }
                                              >
                                                {secondChildNav?.childNavs?.map(
                                                  (thirdChildNav) => {
                                                    if (
                                                      thirdChildNav.name ===
                                                        "CISO Insights" &&
                                                        userRole !== "Ciso" &&
                                                        userRole !== "Operation"
                                                    ) {
                                                      return null;
                                                    }

                                                    return (
                                                      <a
                                                        key={thirdChildNav.path}
                                                        href={`/${thirdChildNav.path}`}
                                                        onClick={(e) =>
                                                          {onNavigate(
                                                            e,
                                                            thirdChildNav.path
                                                          ),storageRemove()}
                                                        }
                                                      >
                                                        {thirdChildNav.text}
                                                      </a>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            }
                                          </>
                                        )
                                      );
                                    }
                                  )}
                                </div>
                              )}
                          </>
                        )
                      );
                    })}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>

      <div className="mainwrap content">
        <div className="topwrap">
          {pathName !== "/access-denied" && (
            <div className="top_Header">
              <div className="topheader">
                <div className="topheader-left">
                  {/* <h3 id="topheader_text">
                    {getPageTitle(pathName, routes)}
                    </h3> */}
                  <BreadCrumb path={pathName} />
                  {/* {get(pageTitleMapper, `${pathName}.module`, "")}{" "}
                  </h3>
                  <h3 id="Page_header">
                    {get(pageTitleMapper, `${pathName}.pageTitle`, "")}
                  </h3> */}
                </div>
                <div className="topheader-right">
                  <div className="notify-block">
                    <a href="">
                      <img src={notification} />
                    </a>
                  </div>
                  <div className="userwrap">
                    <div className="logindes">
                      <h4 className={userName === "sysadmin" ? "userName" : ""}>
                        <a className="">{_.capitalize(userName)}</a>
                      </h4>
                      <h6>
                        {userName !== "sysadmin" ? (
                          <a className="userRole">
                            {userRole === "Ciso"
                              ? "CISO"
                              : userRole === "Department_head"
                              ? "Department Head"
                              : userRole === "Division_head"
                              ? "Division Head"
                              : userRole === "Application_manager"
                              ? "Application Manager"
                              : userRole}
                          </a>
                        ) : null}
                      </h6>
                    </div>
                    <div className="loginico">
                      <a href="">
                        <img src={user} />
                      </a>
                    </div>
                  </div>
                  <div className="logout" title-new="Logout">
                    <a onClick={(e) => logOut(e)}>
                      <img src={logout} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="wrapper"
            style={pathName === "/access-denied" ? { padding: 0 } : {}}
          >
            <Outlet />
          </div>
        </div>
      </div>
      <footer className="footer">
        Powered by Code8
      </footer>
    </div>
  );
};

export default Container;
