import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const buildSuccessRate = createAsyncThunk(
  "post/buildSuccessRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/Landing/buildSuccessRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const deploymentSuccessRate = createAsyncThunk(
  "post/deploymentSuccessRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/Landing/deploymentSuccessRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const flowPredictabilityRate = createAsyncThunk(
  "post/flowPredictabilityRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`/api/v1/Landing/flowPredictabilityRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const Homeapislice = createSlice({
  name: "homeapislice",
  initialState: {
    buildSuccessRatedata: 0,
    buildSuccessRateLoading: false,
    deploymentSuccessRatedata: 0,
    deploymentSuccessRateLoading: false,
    flowPredictabilityRatedata: 0,
    flowPredictabilityRateLoading:false,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      //buildSuccessRate
      .addCase(buildSuccessRate.pending, (state, action) => {
        state.buildSuccessRateLoading = true;
      })
      .addCase(buildSuccessRate.fulfilled, (state, action) => {
        state.buildSuccessRatedata = action.payload;
        state.buildSuccessRateLoading = false;
      })
      .addCase(buildSuccessRate.rejected, (state, action) => {
        state.buildSuccessRateLoading = false;
        state.buildSuccessRatedata = 0;
      })
      //deploymentSuccessRate
      .addCase(deploymentSuccessRate.pending, (state, action) => {
        state.deploymentSuccessRateLoading = true;
      })
      .addCase(deploymentSuccessRate.fulfilled, (state, action) => {
        state.deploymentSuccessRatedata = action.payload;
        state.deploymentSuccessRateLoading = false;
      })
      .addCase(deploymentSuccessRate.rejected, (state, action) => {
        state.deploymentSuccessRateLoading = false;
      })

      //flowPredictabilityRate
      .addCase(flowPredictabilityRate.pending, (state, action) => {
        state.flowPredictabilityRateLoading = true;
      })
      .addCase(flowPredictabilityRate.fulfilled, (state, action) => {
        state.flowPredictabilityRatedata = action.payload;
        state.flowPredictabilityRateLoading = false;
      })
      .addCase(flowPredictabilityRate.rejected, (state, action) => {
        state.flowPredictabilityRateLoading = false;
        state.flowPredictabilityRatedata = 0;
      });
  },
});

export default Homeapislice.reducer;
