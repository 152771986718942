import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getAllDeptDivsionAndApp = createAsyncThunk(
  "post/getAllDeptDivsionAndApp",
  async () => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/cmdb/getAllDeptDivsionAndApplication`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          // body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCSP = createAsyncThunk("get/getCSP", async () => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(
      `/api/v1/CSPForAws/getAllDeptDivsionAndApplication`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        // body: JSON.stringify(payload),
      }
    );
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});
export const getCSPForAws = createAsyncThunk(
  "post/getCSPForAws",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    const { payload } = props;
    try {
      const response = await fetch(`/api/v1/CSPForAws/appCodes/accountId`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  DepartmentDivisionloading: false,
  DepartmentDivisionOption: [],
  CSPForAwsDataLoading: false,
  CSPDataLoading: false,
  CSPForAwsData: [],
  CSPData: [],
  ApplicationOption: [],
  AccountIDOption: [],
  AccountData: [],
  selectedFiltersData: {
    selectedDepartments: [],
    selectedDivisions: [],
    selectedCSV: [],
    selectedApplications: [],
    selectedAccountIDs: [],
  },
};
export const filterSlice = createSlice({
  name: "filterSlice",
  initialState,

  reducers: {
    resetAllFilter: (state) => {
      state.selectedFiltersData = {
        ...initialState.selectedFiltersData,
      };
    },
    resetFilters: (state, action) => {
      const keysToReset = action.payload.keysToReset;
      keysToReset.forEach((key) => {
        state.selectedFiltersData[key] = [];
      });
    },
    setSelectedFiltersData: (state, action) => {
      state.selectedFiltersData = action.payload;
    },
    setApplicationFilterData: (state, action) => {
      state.ApplicationOption = action.payload;
    },
    setAccountIDFilterData: (state, action) => {
      state.AccountIDOption = action.payload;
    },
    setAccountData: (state, action) => {
      state.AccountData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(getAllDeptDivsionAndApp.pending, (state, action) => {
        state.DepartmentDivisionloading = true;
      })
      .addCase(getAllDeptDivsionAndApp.fulfilled, (state, action) => {
        state.DepartmentDivisionOption = action.payload;
        state.DepartmentDivisionloading = false;
      })
      .addCase(getAllDeptDivsionAndApp.rejected, (state, action) => {
        state.DepartmentDivisionloading = false;
      })

      .addCase(getCSPForAws.pending, (state, action) => {
        state.CSPForAwsDataLoading = true;
      })
      .addCase(getCSPForAws.fulfilled, (state, action) => {
        state.CSPForAwsData = action.payload;
        state.CSPForAwsDataLoading = false;
      })
      .addCase(getCSPForAws.rejected, (state, action) => {
        state.CSPForAwsDataLoading = false;
      })

      .addCase(getCSP.pending, (state, action) => {
        state.CSPDataLoading = true;
      })
      .addCase(getCSP.fulfilled, (state, action) => {
        state.CSPData = action.payload;
        state.CSPDataLoading = false;
      })
      .addCase(getCSP.rejected, (state, action) => {
        state.CSPDataLoading = false;
      });
  },
});

export const { resetAllFilter } = filterSlice.actions;
export const { resetFilters } = filterSlice.actions;
export const {
  setSelectedFiltersData,
  setApplicationFilterData,
  setAccountIDFilterData,
  setAccountData,
} = filterSlice.actions;
export default filterSlice.reducer;
