import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const passwordChangeCloudApi = createAsyncThunk(
  "post/cloudPasswordChange",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload} = props;
      const response = await fetch(
        `/api/v1/AWSIAMConfigRest/PasswordChangedDataGraph`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const passwordChangeDueCloudApi = createAsyncThunk(
  "post/cloudPasswordChangeDue",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    console.log ("ICmcloud")
    try {
      const { payload} = props;
      const response = await fetch(
        `/api/v1/AWSIAMConfigRest/PasswordDueDataGraph`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

  
   const initialState= {
      passwordChangeCloudChart: [],
      passwordChangeCloudLoading: false,
      passwordChangeDueCloudChart:[],
      passwordChangeDueCloudLoading:false,
      error: "",
    }
  
    export const iCMCloudSlice = createSlice({
      name: 'iCMCloudSlice',
      initialState,
  
      reducers: {
          resetiCMCloudData: () => {
              return initialState;
             }
      },    extraReducers: (builder) => {
      builder
        //PasswordChange
        .addCase(passwordChangeCloudApi.pending, (state, action) => {
          state.passwordChangeCloudLoading = true;
         })
        .addCase(passwordChangeCloudApi.fulfilled, (state, action) => {
          state.passwordChangeCloudChart = action.payload;
          state.passwordChangeCloudLoading = false;
        })
        .addCase(passwordChangeCloudApi.rejected, (state, action) => {
          state.passwordChangeCloudLoading = false;
        })

        //PasswordChangeDue
        .addCase(passwordChangeDueCloudApi.pending, (state, action) => {
          state.passwordChangeDueCloudLoading = true;
         })
        .addCase(passwordChangeDueCloudApi.fulfilled, (state, action) => {
          state.passwordChangeDueCloudChart = action.payload;
          state.passwordChangeDueCloudLoading = false;
        })
        .addCase(passwordChangeDueCloudApi.rejected, (state, action) => {
          state.passwordChangeDueCloudLoading = false;
        });

    },
});

export const {resetiCMCloudData} = iCMCloudSlice.actions;

export default iCMCloudSlice.reducer;