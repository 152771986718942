import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const dataImportList = createAsyncThunk(
  "post/dataImportList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`/api/v1/list/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  }
)


export const dataImportListCount = createAsyncThunk(
  "post/dataImportListCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`/api/v1/listCountWithSearch`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const dataImportSave = createAsyncThunk("post/dataImportSave", async (props) => {
  try {
    const { savePayload, userName } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `/api/v1/triggerSave/${userName}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(savePayload),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const dataImportGetById = createAsyncThunk("get/dataImportGetById", async (props) => {
  const id = props;
  const Apitoken = cookies.get("apitoken");
  const User = cookies.get("username");
  const res = await fetch(`/api/v1/getById/${id}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      DTOP_API_TOKEN: Apitoken,
      USERNAME: User,
    },
  }).then((resp) => resp.json());
  return res;
});

export const dataImportDelete = createAsyncThunk("DELETE/dataImportDelete", async (props) => {
  try {
    const { id, userName } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `/api/v1/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});



const initialState = {
  tableDILoading: false,
  listTableDIData: [],
  tableCountDILoading: false,
  listTableDataDICount: [],
  tableDIDeleteReload: false,
  tableDISaveReload: false,
  saveDILoader:false,
  getDILoader:false,
  deleteDILoader:false,
};
export const DataImportConfigSlice = createSlice({
  name: 'DataImportConfigSlice',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

      .addCase(dataImportList.pending, (state, action) => {
        state.tableDILoading = true;
      })
      .addCase(dataImportList.fulfilled, (state, action) => {
        state.listTableDIData = action.payload;
        state.tableDILoading = false;
        state.tableDIDeleteReload = false;
        state.tableDISaveReload = false;
      })
      .addCase(dataImportList.rejected, (state, action) => {
        state.tableDILoading = false;
      })


      .addCase(dataImportListCount.pending, (state, action) => {
        state.tableCountDILoading = true;
      })
      .addCase(dataImportListCount.fulfilled, (state, action) => {
        state.listTableDataDICount = action.payload;
        state.tableCountDILoading = false;
      })
      .addCase(dataImportListCount.rejected, (state, action) => {
        state.tableCountDILoading = false;
      })
      .addCase(dataImportDelete.pending, (state, action) => {
        state.deleteDILoader = true;
      })
      .addCase(dataImportDelete.fulfilled, (state, action) => {
        state.tableDIDeleteReload = true;
        state.tableDISaveReload = false;
        state.deleteDILoader = false;
      })
      .addCase(dataImportSave.pending, (state, action) => {
        // state.tableDIDeleteReload = false;
        //state.tableDISaveReload = true;
        state.saveDILoader = true;
      })
      .addCase(dataImportSave.fulfilled, (state, action) => {
        state.tableDIDeleteReload = false;
        state.tableDISaveReload = true;
        state.saveDILoader = false;
      })
      .addCase(dataImportSave.rejected, (state, action) => {
        // state.tableDIDeleteReload = false;
        // state.tableDISaveReload = true;
        state.saveDILoader = false;
      })
      .addCase(dataImportGetById.pending, (state, action) => {
        state.getDILoader = true;
      })
      .addCase(dataImportGetById.fulfilled, (state, action) => {
        state.getDILoader = false;
      })
      .addCase(dataImportGetById.rejected, (state, action) => {
        state.getDILoader = false;
      })

  },

});
export const { } = DataImportConfigSlice.actions;
export default DataImportConfigSlice.reducer;