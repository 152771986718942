import { createSlice } from "@reduxjs/toolkit";

const PeopleDataSlice = createSlice({
    name: 'Peopledata',
    initialState: {
        topContributorsDDmonth: '',
        topContributorsDDname: '',
        topContributorsDDyear: '',
    },
    reducers: {
      setTopconDDmonth: (state, action) => {
        state.topContributorsDDmonth = action.payload;
      },
      setTopconDDname: (state, action) => {
        state.topContributorsDDname = action.payload;
      },
      setTopconDDyear: (state, action) => {
        state.topContributorsDDyear = action.payload;
      },
    //   setPiiTypeList:(state, action) => {
    //     state.piiType = action.payload;
    //   }
    }
  });
  
  export const { setTopconDDmonth,setTopconDDname,setTopconDDyear} = PeopleDataSlice.actions;
  export default PeopleDataSlice.reducer;
