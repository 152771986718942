import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { propTypes } from 'react-bootstrap/esm/Image';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getFlowDistributionApi = createAsyncThunk(
    "post/getFlowDistributionApi",
    async (props) => {        
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowDistribution/main`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const getFlowDistributionApi1 = createAsyncThunk(
    "post/getFlowDistributionApi1",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/flowMetrics/flowDistributionMainChartData`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const distributionDrillDownApi = createAsyncThunk(
    "post/distributionDrillDownApi",
    async (props) => {
        const {payload,itemsPerPage,pageNumber}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/flowMetrics/flowDistributionDrillDownDataWithSearchAndPagination/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const distributionDrillDownCountApi = createAsyncThunk(
    "post/distributionDrillDownCountApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/flowMetrics/flowDistributionDrillDownDataListCount`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const getFlowVelocityApi = createAsyncThunk(
    "post/getFlowVelocityApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/flowMetrics/flowVelocityMain/Dashboard`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const getFlowVelocityApi1 = createAsyncThunk(
    "post/getFlowVelocityApi1",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/flowMetrics/flowVelocityMain/Dashboard`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const getFlowEfficiencyApi = createAsyncThunk(
    "post/getFlowEfficiencyApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {            
            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowEfficiency/main`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const efficiencyDrilldownApi = createAsyncThunk(
    "post/efficiencyDrilldownApi",
    async (props) => {
        const {payload,pageNumber,itemsPerPage}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowEfficiency/main/summary/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const efficiencyDrilldownCountApi = createAsyncThunk(
    "post/efficiencyDrilldownCountApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowEfficiency/main/summaryCount`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const efficiencyIssuetApi = createAsyncThunk(
    "post/efficiencyIssuetApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowEfficiency/main/drilldown`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const getFlowPredicatablityApi = createAsyncThunk(
    "post/getFlowPredicatablityApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowMetrics/ddflowpredicatablitymain`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const getflowLoadMainApi = createAsyncThunk(
    "post/getflowLoadMainApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowMetrics/flowLoadMain`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const flowLoadDrillDownApi = createAsyncThunk(
    "post/flowLoadDrillDownApi",
    async (props) => {
        const {payload,pageNumber,itemsPerPage}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            
            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowMetrics/flowLoadMain/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const flowLoadDrillDownCountApi = createAsyncThunk(
    "post/flowLoadDrillDownCountApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowMetrics/flowLoadMainDDCount`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);


export const flowVelocityMainDrillDown1Api = createAsyncThunk(
    "post/flowVelocityMainDrillDown1Api",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/flowMetrics/flowVelocityMain/DrillDown1`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const flowVelocityMainDrillDown2Api = createAsyncThunk(
    "post/flowVelocityMainDrillDown2Api",
    async (props) => {
        const {payload,pageNumber,itemsPerPage}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/flowMetrics/flowVelocity/DrillDown2/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const flowVelocityMainDrillDown2CountApi = createAsyncThunk(
    "post/flowVelocityMainDrillDown2CountApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/flowMetrics/flowVelocityMain/DrillDown2/Count`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const flowPredictabilityMainDrillDown1Api = createAsyncThunk(
    "post/flowPredictabilityMainDrillDown1Api",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowMetrics/ddflowpredicatablitymonth`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const flowPredictabilityDrillDown2Api = createAsyncThunk(
    "post/flowPredictabilityDrillDown2Api",
    async (props) => {
        const {payload,pageNumber,itemsPerPage}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowEfficiency/main/FPDDOneServicePaginationWithSearch/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const flowPredictabilityMainDrillDown2CountApi = createAsyncThunk(
    "post/flowEfficiencyMainDrillDown2CountApi",
    async (props) => {
        const {payload}= props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`/api/v1/safeFlowMetrics/flow/flowEfficiency/main/FPDDOneServicePaginationWithSearchCount`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);


    const initialState = {
        getFlowDistributionData: [],
        getFlowDistributionLoading: false,
        getFlowDistributionData1: [],
        getFlowDistributionLoading1: false,
        DistributionDrillDownData:[],
        DistributionDrillDownLoading:false,
        DistributionDrillDownCountData:[],
        DistributionDrillDownCountLoading:false,

        getFlowVelocityData:[],
        getFlowVelocityLoading:false,
        getFlowVelocityData1:[],
        getFlowVelocityLoading1:false,
        getFlowVelocityDrillDown1Data:[],
        getFlowVelocityDrillDown1Loading:false,
        getFlowVelocityDrillDown2Data:[],
        getFlowVelocityDrillDown2Loading:false,
        getFlowVelocityDrillDownCountData:[],
        getFlowVelocityDrillDownCountLoading:false,

        getFlowEfficiencyData:[],
        getFlowEfficiencyLoading:false,
        efficiencyDrilldownData:[],
        efficiencyDrilldownLoading:false,
        efficiencyDrilldownCountData:[],
        efficiencyDrilldownCountLoading:false,
        efficiencyIssuetData:[],
        efficiencyIssuetLoading:false,

        getflowpredicatablityData:[],
        getflowpredicatablityLoading:false,
        getflowpredicatablityDrillDown1Data:[],
        getflowpredicatablityDrillDown1Loading:false,
        getflowpredicatablityDrillDown2Data:[],
        getflowpredicatablityDrillDown2Loading:false,
        getflowpredicatablityDrillDownCountData:[],
        getflowpredicatablityDrillDownCountLoading:false,

        getflowLoadMainData:[],
        getflowLoadMainLoading:false,
        flowLoadDrillDownData:[],
        flowLoadDrillDownLoading:false,
        flowLoadDrillDownCountData:[],
        flowLoadDrillDownCountLoading:false,
        error : false,
    };

export const FlowMetricsSlice = createSlice({
    name: 'FlowMetricsSlice',
    initialState,


    reducers: {
       resetFlowMetricsData: () => {
        return initialState;
       }
    },
    extraReducers: (builder) => {
        builder

        //Flow Distribution
                .addCase(getFlowDistributionApi.pending, (state, action) => {
                    state.getFlowDistributionLoading = true ;
                })
                .addCase(getFlowDistributionApi.fulfilled, (state, action) => {
                    state.getFlowDistributionData = action.payload;
                    state.getFlowDistributionLoading = false;

                })
                .addCase(getFlowDistributionApi.rejected, (state, action) => {
                     state.getFlowDistributionLoading = false;
                })
        //Flow Distribution1
                .addCase(getFlowDistributionApi1.pending, (state, action) => {
                    state.getFlowDistributionLoading1 = true ;
                })
                .addCase(getFlowDistributionApi1.fulfilled, (state, action) => {
                    state.getFlowDistributionData1 = action.payload;
                    state.getFlowDistributionLoading1 = false;

                })
                .addCase(getFlowDistributionApi1.rejected, (state, action) => {
                    state.filterLoading = false;
                    state.getFlowDistributionLoading1 = [];
                })

        //Flow Distribution drilldown
                .addCase(distributionDrillDownApi.pending, (state, action) => {
                    state.DistributionDrillDownLoading = true ;
                })
                .addCase(distributionDrillDownApi.fulfilled, (state, action) => {
                    state.DistributionDrillDownData = action.payload;
                    state.DistributionDrillDownLoading = false;

                })
                .addCase(distributionDrillDownApi.rejected, (state, action) => {
                    state.filterLoading = false;
                    state.DistributionDrillDownLoading = [];
                })
        //Flow Distribution drilldown count
                .addCase(distributionDrillDownCountApi.pending, (state, action) => {
                    state.DistributionDrillDownCountLoading = true ;
                })
                .addCase(distributionDrillDownCountApi.fulfilled, (state, action) => {
                    state.DistributionDrillDownCountData = action.payload;
                    state.DistributionDrillDownCountLoading = false;

                })
                .addCase(distributionDrillDownCountApi.rejected, (state, action) => {
                    state.filterLoading = false;
                    state.DistributionDrillDownCountLoading = [];
                })

        //Flow Velocity
                .addCase(getFlowVelocityApi.pending, (state, action) => {
                    state.getFlowVelocityLoading = true ;
                })
                .addCase(getFlowVelocityApi.fulfilled, (state, action) => {
                    state.getFlowVelocityData = action.payload;
                    state.getFlowVelocityLoading = false;

                })
                .addCase(getFlowVelocityApi.rejected, (state, action) => {
                     state.getFlowVelocityLoading = [];
                })
        //Flow Velocity1
                .addCase(getFlowVelocityApi1.pending, (state, action) => {
                    state.getFlowVelocityLoading1 = true ;
                })
                .addCase(getFlowVelocityApi1.fulfilled, (state, action) => {
                    state.getFlowVelocityData1 = action.payload;
                    state.getFlowVelocityLoading1 = false;

                })
                .addCase(getFlowVelocityApi1.rejected, (state, action) => {
                     state.getFlowVelocityLoading1 = [];
                })

                .addCase(flowVelocityMainDrillDown1Api.pending, (state, action) => {
                    state.getFlowVelocityDrillDown1Loading = true ;
                })
                .addCase(flowVelocityMainDrillDown1Api.fulfilled, (state, action) => {
                    state.getFlowVelocityDrillDown1Data = action.payload;
                    state.getFlowVelocityDrillDown1Loading = false;
                })
                .addCase(flowVelocityMainDrillDown1Api.rejected, (state, action) => {
                     state.getFlowVelocityDrillDown1Loading = false;
                })

                .addCase(flowVelocityMainDrillDown2Api.pending, (state, action) => {
                    state.getFlowVelocityDrillDown2Loading = true ;
                })
                .addCase(flowVelocityMainDrillDown2Api.fulfilled, (state, action) => {
                    state.getFlowVelocityDrillDown2Data = action.payload;
                    state.getFlowVelocityDrillDown2Loading = false;
                })
                .addCase(flowVelocityMainDrillDown2Api.rejected, (state, action) => {
                     state.getFlowVelocityDrillDown2Loading = false;
                })

                .addCase(flowVelocityMainDrillDown2CountApi.pending, (state, action) => {
                    state.getFlowVelocityDrillDownCountLoading = true ;
                })
                .addCase(flowVelocityMainDrillDown2CountApi.fulfilled, (state, action) => {
                    state.getFlowVelocityDrillDownCountData = action.payload;
                    state.getFlowVelocityDrillDownCountLoading = false;
                })
                .addCase(flowVelocityMainDrillDown2CountApi.rejected, (state, action) => {
                     state.getFlowVelocityDrillDownCountLoading = false;
                })

        //Flow Efficiency
                .addCase(getFlowEfficiencyApi.pending, (state, action) => {
                    state.getFlowEfficiencyLoading = true ;
                })
                .addCase(getFlowEfficiencyApi.fulfilled, (state, action) => {
                    state.getFlowEfficiencyData = action.payload;
                    state.getFlowEfficiencyLoading = false;

                })
                .addCase(getFlowEfficiencyApi.rejected, (state, action) => {
                     state.getFlowEfficiencyLoading = [];
                })

        //Flow Efficiency drilldown
                .addCase(efficiencyDrilldownApi.pending, (state, action) => {
                    state.efficiencyDrilldownLoading = true ;
                })
                .addCase(efficiencyDrilldownApi.fulfilled, (state, action) => {
                    state.efficiencyDrilldownData = action.payload;
                    state.efficiencyDrilldownLoading = false;

                })
                .addCase(efficiencyDrilldownApi.rejected, (state, action) => {
                     state.efficiencyDrilldownLoading = [];
                })

        //Flow Efficiency drilldown count
                .addCase(efficiencyDrilldownCountApi.pending, (state, action) => {
                    state.efficiencyDrilldownCountLoading = true ;
                })
                .addCase(efficiencyDrilldownCountApi.fulfilled, (state, action) => {
                    state.efficiencyDrilldownCountData = action.payload;
                    state.efficiencyDrilldownCountLoading = false;

                })
                .addCase(efficiencyDrilldownCountApi.rejected, (state, action) => {
                     state.efficiencyDrilldownCountLoading = [];
                })

        //Flow Efficiency issue box
                .addCase(efficiencyIssuetApi.pending, (state, action) => {
                    state.efficiencyIssuetLoading = true ;
                })
                .addCase(efficiencyIssuetApi.fulfilled, (state, action) => {
                    state.efficiencyIssuetData = action.payload;
                    state.efficiencyIssuetLoading = false;

                })
                .addCase(efficiencyIssuetApi.rejected, (state, action) => {
                     state.efficiencyIssuetLoading = [];
                })

        //Flow Predicatablity
                .addCase(getFlowPredicatablityApi.pending, (state, action) => {
                    state.getflowpredicatablityLoading = true ;
                })
                .addCase(getFlowPredicatablityApi.fulfilled, (state, action) => {
                    state.getflowpredicatablityData = action.payload;
                    state.getflowpredicatablityLoading = false;
                })
                .addCase(getFlowPredicatablityApi.rejected, (state, action) => {
                     state.getflowpredicatablityLoading = [];
                })

                .addCase(flowPredictabilityMainDrillDown1Api.pending, (state, action) => {
                    state.getflowpredicatablityDrillDown1Loading = true ;
                })
                .addCase(flowPredictabilityMainDrillDown1Api.fulfilled, (state, action) => {
                    state.getflowpredicatablityDrillDown1Data = action.payload;
                    state.getflowpredicatablityDrillDown1Loading = false;
                })
                .addCase(flowPredictabilityMainDrillDown1Api.rejected, (state, action) => {
                     state.getflowpredicatablityDrillDown1Loading = false;
                })

                .addCase(flowPredictabilityDrillDown2Api.pending, (state, action) => {
                    state.getflowpredicatablityDrillDown2Loading = true ;
                })
                .addCase(flowPredictabilityDrillDown2Api.fulfilled, (state, action) => {
                    state.getflowpredicatablityDrillDown2Data = action.payload;
                    state.getflowpredicatablityDrillDown2Loading = false;
                })
                .addCase(flowPredictabilityDrillDown2Api.rejected, (state, action) => {
                     state.getflowpredicatablityDrillDown2Loading = false;
                })

                .addCase(flowPredictabilityMainDrillDown2CountApi.pending, (state, action) => {
                    state.getflowpredicatablityDrillDownCountLoading = true ;
                })
                .addCase(flowPredictabilityMainDrillDown2CountApi.fulfilled, (state, action) => {
                    state.getflowpredicatablityDrillDownCountData = action.payload;
                    state.getflowpredicatablityDrillDownCountLoading = false;
                })
                .addCase(flowPredictabilityMainDrillDown2CountApi.rejected, (state, action) => {
                     state.getflowpredicatablityDrillDownCountLoading = false;
                })

        //Flow Load
                .addCase(getflowLoadMainApi.pending, (state, action) => {
                    state.getflowLoadMainLoading = true ;
                    state.error=false;

                })
                .addCase(getflowLoadMainApi.fulfilled, (state, action) => {
                    state.getflowLoadMainData = action.payload;
                    state.getflowLoadMainLoading = false;
                    state.error=false;
                })
                .addCase(getflowLoadMainApi.rejected, (state, action) => {
                     state.getflowLoadMainLoading = [];
                     state.error=true;
                })

        //Flow load drilldown
                .addCase(flowLoadDrillDownApi.pending, (state, action) => {
                    state.flowLoadDrillDownLoading = true ;
                    state.error=false;
                })
                .addCase(flowLoadDrillDownApi.fulfilled, (state, action) => {
                    state.flowLoadDrillDownData = action.payload;
                    state.flowLoadDrillDownLoading = false;
                    state.error=false;
                })
                .addCase(flowLoadDrillDownApi.rejected, (state, action) => {
                     state.flowLoadDrillDownLoading = [];
                     state.error=true;
                })
        //Flow load drilldown
                .addCase(flowLoadDrillDownCountApi.pending, (state, action) => {
                    state.flowLoadDrillDownCountLoading = true ;
                })
                .addCase(flowLoadDrillDownCountApi.fulfilled, (state, action) => {
                    state.flowLoadDrillDownCountData = action.payload;
                    state.flowLoadDrillDownCountLoading = false;
                })
                .addCase(flowLoadDrillDownCountApi.rejected, (state, action) => {
                     state.flowLoadDrillDownCountLoading = [];
                });

},

});
export const {resetFlowMetricsData} = FlowMetricsSlice.actions;
export default FlowMetricsSlice.reducer;