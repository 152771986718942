import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    offcanvasState: {
      isDrilldownOpen: false,
      title: "",
      dropDownMenuOptions: [],
      selectedValue: {},
      selectedData: [],
      handleDdMenuChange: () => {},
      isDropDownShown:true
    },
    offcanvasThreeState: {
      isDrilldownThreeOpen: false,
      locDrilldown:false,
      title: "",
      static:false,
      // dropDownMenuOptions: [],
      // selectedValue: {},
      selectedData: [],
      //handleDdMenuChange: () => {},
      isDropDownShown:true
    },
    accordionModal :{
       accordionData:[]
    },
    offcanvasLastState: {
      isDrilldownLastOpen: false,
      //locDrilldown:false,
      title: "",
      static:false,
      // dropDownMenuOptions: [],
      // selectedValue: {},
      selectedData: [],
      //handleDdMenuChange: () => {},
      isDropDownShown:true
    },
    drillDownSelectionState: {
      selectedLevelOne: "",
    },
    completeTable: {
      limit: 10,
      pageNo:0,
      pageCount:"",
      listCount:"",
      activeKey:"",
      searchKey:"",
      filterKey:"",
    },
    loggedUser:{
      userName:"",
      userRole:"",
      userMail:"",
      token:""
    },
    UserMail:{
      mailId:""
    },
    isReload:"false",
    assessmentData:[],
    selectedIds:[],
    pageLoading:true,
  },
  reducers: {
    setIsOffCanvasOpen: {
      reducer(state, action) {
        state.offcanvasState = action.payload;
      },
    },
    setIsOffCanvasThreeOpen: {
      reducer(state, action) {
        state.offcanvasThreeState = action.payload;
      },
    },
    setIsOffCanvasLastOpen: {
      reducer(state, action) {
        state.offcanvasLastState = action.payload;
      },
    },
    setSelectedLevelOne: {
      reducer(state, action) {
        state.drillDownSelectionState.selectedLevelOne = action.payload;
      },
    },
    setAccordionData:{
      reducer(state,action){
        state.accordionModal.accordionData=action.payload;
      }
    },
    setCompleteTableLimit: {
      reducer(state, action) {
        state.completeTable.limit = action.payload;
      },
    },
    setCompleteTablePageNo: {
      reducer(state, action) {
        state.completeTable.pageNo = action.payload;
      },
    },
    setCompleteTablepageCount: {
      reducer(state, action) {
        state.completeTable.pageCount = action.payload;
      },
    },
    setCompleteTablelistCount: {
      reducer(state, action) {
        state.completeTable.listCount = action.payload;
      },
    },
    setCompleteTableactiveKey: {
      reducer(state, action) {
        state.completeTable.activeKey = action.payload;
      },
    },
    setCompleteTableSearchKey: {
      reducer(state, action) {
        state.completeTable.searchKey = action.payload;
      },
    },
    setCompleteTableFilterKey: {
      reducer(state, action) {
        state.completeTable.filterKey = action.payload;
      },
    },
    hideOffCanvas: {
      reducer(state, action) {
        state.offcanvasState.isDrilldownOpen = action.payload;
      },
    },
    hideOffCanvasThree: {
      reducer(state, action) {
        state.offcanvasThreeState.isDrilldownThreeOpen = action.payload;
      },
    },
    hideOffCanvasLast: {
      reducer(state, action) {
        state.offcanvasLastState.isDrilldownLastOpen = action.payload;
      },
    },
    
    locdrilldownthree: {
      reducer(state, action) {
        state.offcanvasThreeState.locDrilldown = action.payload;
      },
    },
    setSelectedDdMenuValue: {
      reducer(state, action) {
        state.offcanvasState.selectedValue = action.payload;
      },
    },
    setSelectedData: {
      reducer(state, action) {
        state.offcanvasState.selectedData = action.payload;
      },
    },
    setLoggedUser:{
      reducer(state,action){
        state.loggedUser = action.payload
      }
    },
    setUserMail:{
      reducer(state,action){
        state.UserMail = action.payload
      }
    },
    setIsReload:{
      reducer(state,action){
        state.isReload = action.payload
      }
    },
    setAssessmentData:{
      reducer(state,action){
        state.assessmentData = action.payload
      }
    },
    setSelectedIds:{
      reducer(state,action){
        state.selectedIds = action.payload
      }
    },
    setPageLoading:{
      reducer(state,action){
        state.pageLoading = action.payload
      }
    },
  },
});
export const {
  setIsOffCanvasOpen,
  setIsOffCanvasThreeOpen,
  setIsOffCanvasLastOpen,
  setSelectedLevelOne,
  setCompleteTableLimit,
  setCompleteTablePageNo,
  setCompleteTablepageCount,
  setCompleteTablelistCount,
  setCompleteTableactiveKey,
  setCompleteTableSearchKey,
  setCompleteTableFilterKey,
  hideOffCanvas,
  hideOffCanvasThree,
  hideOffCanvasLast,
  locdrilldownthree,
  setSelectedDdMenuValue,
  setSelectedData,
  setLoggedUser,
  setUserMail,
  setIsReload,
  setPageLoading,
  setAssessmentData,
  setSelectedIds,
  setAccordionData
} = commonSlice.actions;


export default commonSlice.reducer;
