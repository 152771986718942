import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const cisListData = createAsyncThunk(
  "post/cisListData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/list/cisBenchMark/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const cisListCounts = createAsyncThunk(
  "post/cisListCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/cisBenchMarkListPaginationCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const cIStargetDate = createAsyncThunk(
  "post/targetDate",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/getTargetDates`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const cISActionPlanApi = createAsyncThunk(
  "post/ActionPlan",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/CisBenchmarkActionPlanCounts`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const cISUnplannedApi = createAsyncThunk(
  "post/Unplanned",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/unplanned/count`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const cISApplicationApi = createAsyncThunk(
  "post/Application",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/CisBenchmarkPerApplication`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const cISCriticalityApi = createAsyncThunk(
  "post/Criticality",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/CisComplianceByCriticality`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const cISServerityApi = createAsyncThunk(
  "post/Serverity",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/getServerityLevels`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const cISTargetTrendApi = createAsyncThunk(
  "post/Target",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(
        `/api/v1/tenableNessusComplianceRest/cis/getSixMonthTargetDate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const currentCISTableApi = createAsyncThunk(
  "post/CISTable",
  async (props) => {
      try {
      const { pageNumber, itemsPerPage, payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`/api/v1/tenableNessusComplianceRest/lists/cisBenchMarkCurrentFindings/${pageNumber}/${itemsPerPage}`, {
          method: 'POST',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              DTOP_API_TOKEN: Apitoken,
              USERNAME: User,

          },
          body: JSON.stringify(payload)
      })
      const data = await response.json(); 
      return response.ok ?  data : Promise.reject(data);
  } catch(error) {
      console.log("Error", error);
      throw(error)
  }
  });

  export const currentCISTableCountApi = createAsyncThunk(
    "post/CISTableCount",
    async (props) => {
        try {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const response = await fetch(`/api/v1/tenableNessusComplianceRest/cisBenchMarkListPaginationCountForCurrentFindings`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        })
        const data = await response.json(); 
        return response.ok ?  data : Promise.reject(data);
    } catch(error) {
        console.log("Error", error);
        throw(error)
    }
    });

    export const fixedCISTableApi = createAsyncThunk(
      "post/fixedCISTableApi",
      async (props) => {
          try {
          const {  pageNumber, itemsPerPage, payload } = props;
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          const response = await fetch(`/api/v1/tenableNessusComplianceRest/list/cisBenchMarkFixedFindings/${pageNumber}/${itemsPerPage}
          `, {
              method: 'POST',
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  DTOP_API_TOKEN: Apitoken,
                  USERNAME: User,    
              },
              body: JSON.stringify(payload)
          })
          const data = await response.json(); 
          return response.ok ?  data : Promise.reject(data);
      } catch(error) {
          console.log("Error", error);
          throw(error)
      }
      });


    export const fixedCISTableCountApi = createAsyncThunk(
      "post/fixedCISTableCountApi",
      async (props) => {
          try {
          const {  payload } = props;
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          const response = await fetch(`/api/v1/tenableNessusComplianceRest/cisBenchMarkListPaginationCountForFixedFindings
          `, {
              method: 'POST',
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  DTOP_API_TOKEN: Apitoken,
                  USERNAME: User,
              },
              body: JSON.stringify(payload)
          })
          const data = await response.json(); 
          return response.ok ?  data : Promise.reject(data);
      } catch(error) {
          console.log("Error", error);
          throw(error)
      }
      });

      



  const initialState = {
    cisListData: [],
    cisListLoading: false,
    cisListCounts: [],
    cisListCountLoading: [],
    cIStargetDateChart:[],
    cIStargetDateLoading:false,
    cISActionPlanChart:[],
    cISActionPlanChartLoading:false,
    cISUnplannedChart:[],
    cISUnplannedChartLoading:false,
    cISApplicationChart:[],
    cISApplicationChartLoading:false,
    cISCriticalityChart:[],
    cISCriticalityChartLoading:false,
    cISServerityChart:[],
    cISServerityChartLoading:false,
    cISTargetTrendChart:[],
    cISTargetTrendChartLoading:false,
    currentCISTable:[],
    currentCISTableLoading:false,
    currentCISTableCount:[],
    currentCISTableCountLoading:false,
    fixedCISTable:[],
    fixedCISTableLoading:false,
    fixedCISTableCount:[],
    fixedCISTableCountLoading:false,
    error: "",

}
export const CISBenchmarkSlice = createSlice({
    name: 'cisSlice',
    initialState,

    reducers: {
      resetCISBenchmarkData: () => {
            return initialState;
           }
    },
    extraReducers: (builder) => {
        builder
      //cisListData
      .addCase(cisListData.pending, (state, action) => {
        state.cisListLoading = true;
        state.error = "";
      })
      .addCase(cisListData.fulfilled, (state, action) => {
        state.cisListData = action.payload;
        state.cisListLoading = false;
      })
      .addCase(cisListData.rejected, (state, action) => {
        state.cisListLoading = false;
        state.cisListData = [];
        state.error = "something went wrong";
      })
      //cisListCount
      .addCase(cisListCounts.pending, (state, action) => {
        state.cisListCountLoading = true;
      })
      .addCase(cisListCounts.fulfilled, (state, action) => {
        state.cisListCounts = action.payload;
        state.cisListCountLoading = false;
      })
      .addCase(cisListCounts.rejected, (state, action) => {
        state.cisListCountLoading = false;
        state.error = "something went wrong";
      })

      //CISTARGETDATE
      .addCase(cIStargetDate.pending, (state, action) => {
        state.cisListCountLoading = true;
      })
      .addCase(cIStargetDate.fulfilled, (state, action) => {
        state.cIStargetDateChart = action.payload;
        state.cIStargetDateLoading = false;
      })
      .addCase(cIStargetDate.rejected, (state, action) => {
        state.cisListCountLoading = false;
       })

       //Actionplan
       .addCase(cISActionPlanApi.pending, (state, action) => {
        state.cISActionPlanChartLoading = true;
      })
      .addCase(cISActionPlanApi.fulfilled, (state, action) => {
        state.cISActionPlanChart = action.payload;
        state.cISActionPlanChartLoading = false;
      })
      .addCase(cISActionPlanApi.rejected, (state, action) => {
        state.cISActionPlanChartLoading = false;
       })

       //Unplannedchart
       .addCase(cISUnplannedApi.pending, (state, action) => {
        state.cISUnplannedChartLoading = true;
      })
      .addCase(cISUnplannedApi.fulfilled, (state, action) => {
        state.cISUnplannedChart = action.payload;
        state.cISUnplannedChartLoading = false;
      })
      .addCase(cISUnplannedApi.rejected, (state, action) => {
        state.cISUnplannedChartLoading = false;
       })

      //Applicationchart
      .addCase(cISApplicationApi.pending, (state, action) => {
       state.cISApplicationChartLoading = true;
      })
      .addCase(cISApplicationApi.fulfilled, (state, action) => {
       state.cISApplicationChart = action.payload;
       state.cISApplicationChartLoading = false;
      })
      .addCase(cISApplicationApi.rejected, (state, action) => {
        state.cISApplicationChartLoading = false;
      })
       //Criticality
      .addCase(cISCriticalityApi.pending, (state, action) => {
       state.cISCriticalityChartLoading = true;
      })
      .addCase(cISCriticalityApi.fulfilled, (state, action) => {
       state.cISCriticalityChart = action.payload;
       state.cISCriticalityChartLoading = false;
      })
      .addCase(cISCriticalityApi.rejected, (state, action) => {
        state.cISCriticalityChartLoading = false;
      })

      //Serverity
      .addCase(cISServerityApi.pending, (state, action) => {
        state.cISServerityChartLoading = true;
      })
      .addCase(cISServerityApi.fulfilled, (state, action) => {
        state.cISServerityChart = action.payload;
        state.cISServerityChartLoading = false;
      })
      .addCase(cISServerityApi.rejected, (state, action) => {
        state.cISServerityChartLoading = false;
      })

      //Target
      .addCase(cISTargetTrendApi.pending, (state, action) => {
        state.cISTargetTrendChartLoading = true;
      })
      .addCase(cISTargetTrendApi.fulfilled, (state, action) => {
        state.cISTargetTrendChart = action.payload;
        state.cISTargetTrendChartLoading = false;
      })
      .addCase(cISTargetTrendApi.rejected, (state, action) => {
        state.cISTargetTrendChartLoading = false;
      })

      //currentTable
      .addCase(currentCISTableApi.pending, (state, action) => {
        state.currentCISTableLoading = true;
      })
      .addCase(currentCISTableApi.fulfilled, (state, action) => {
        state.currentCISTable = action.payload;
        state.currentCISTableLoading = false;
      })
      .addCase(currentCISTableApi.rejected, (state, action) => {
        state.currentCISTableLoading = false;
      })

      //currentTableCount
      .addCase(currentCISTableCountApi.pending, (state, action) => {
        state.currentCISTableCountLoading = true;
      })
      .addCase(currentCISTableCountApi.fulfilled, (state, action) => {
        state.currentCISTableCount = action.payload;
        state.currentCISTableCountLoading = false;
      })
      .addCase(currentCISTableCountApi.rejected, (state, action) => {
        state.currentCISTableCountLoading = false;
      })

      //currentTableCount
      .addCase(fixedCISTableApi.pending, (state, action) => {
        state.fixedCISTableLoading = true;
      })
      .addCase(fixedCISTableApi.fulfilled, (state, action) => {
        state.fixedCISTable = action.payload;
        state.fixedCISTableLoading = false;
      })
      .addCase(fixedCISTableApi.rejected, (state, action) => {
        state.fixedCISTableLoading = false;
      })

      //currentTableCount
      .addCase(fixedCISTableCountApi.pending, (state, action) => {
        state.fixedCISTableCountLoading = true;
      })
      .addCase(fixedCISTableCountApi.fulfilled, (state, action) => {
        state.fixedCISTableCount = action.payload;
        state.fixedCISTableCountLoading = false;
      })
      .addCase(fixedCISTableCountApi.rejected, (state, action) => {
        state.fixedCISTableCountLoading = false;
      });
  },
});

export const {resetCISBenchmarkData} = CISBenchmarkSlice.actions;
export default CISBenchmarkSlice.reducer;
