import { configureStore } from "@reduxjs/toolkit";
import { api } from "./services/baseApiSetup";
import observabilitySlice from "../features/observability/observabilitySlice";
import commonSlice from "./commonSlice";
import efficiencySlice from "../features/efficiency/efficiencySlice";
import configurationSlice from "../features/configuration/configurationSlice";
import adminActivityslice from "../features/gnc/adminActivity/adminActivityslice";
import piiUserBarSlice from "../features/gnc/GNCSlice.js";
import PeopleDataSlice from "../features/observability/peopleMetrics/PeopleDataSlice.js";
import HomeSlice from "../features/homePage/HomeSlice.js";
import AssetSlice from "../features/configuration/cmdb/AssetConfiguration/AssetSlice.js";
import CMDBSlice from "../features/configuration/cmdb/CMDBSlice.js";
import CostSlice from "../features/gnc/CloudCostInsights/CostSlice";
import CBudgetSlice from "../features/configuration/cmdb/CloudBudget/CBudgetSlice";
import VulnerabilitiesSlice from "../features/gnc/Vulnerabilites/VulnerabilitiesSlice";
import SecurityHubSlice from "../features/gnc/complianceCheck/securityHub/SecurityHubSlice";
 import UserAccessReviewSlice from "../features/gnc/UserAccessReview/UserAccessReviewSlice";
 import PIIConfigurationSlice from "../features/configuration/settings/PIIConfiguration/PIIConfigurationSlice";
 import appConfigSlice from "../features/efficiency/appConfig/appConfigSlice";
import EmailScheduleConfigSlice from "../features/configuration/settings/Schedulers/EmailScheduleConfiguration/EmailScheduleSlice";
import CISBenchmarkSlice from "../features/gnc/CISBenchmarkCompliance/CISBenchmarkSlice";
import HomeApiSlice from "../features/homePage/HomeApiSlice";
import LicenseSlice from "../features/gnc/LicenseManagement/LicenseSlice";
import reviewslice from "../features/gnc/CisoUserAccess/reviewslice";
import iCMSlice from "../features/gnc/complianceCheck/complianceManagement/iCMSlice";
import iCMCloudSlice from "../features/gnc/complianceCheck/ComplianceManagementCloud/iCMCloudSlice";
import TRMGSlice from "../features/gnc/TRMG/TRMGSlice";
import Circular9Slice from "../features/gnc/circular9/Circular9Slice";
import AuditLogSlice from "../features/efficiency/auditLog/AuditLogSlice.js";
import ToolsSlice from "../features/configuration/settings/tools/ToolsSlice.js";
import GroupSlice from "../features/configuration/settings/groups/GroupSlice.js";
import OrgInfoSlice from "../features/configuration/cmdb/Organizational-Info/OrgInfoSlice.js";
import FlowMetricsSlice from "../features/observability/flowMetrics/FlowMetricsSlice.js";
import DataImportConfigSlice from "../features/configuration/settings/Schedulers/DataImportScheduler/DataImportSlice";
import  filterSlice from "./common-components/CommonFilter/complainceFilterSlice.js";
import PeopleMetricsSlice from "../features/observability/PeopleMetricsObs/PeopleMetricsSlice";

 export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    observability: observabilitySlice,
    common: commonSlice,
    efficiency:efficiencySlice,
    configuration: configurationSlice, 
    adminActivity:adminActivityslice,
    Name:piiUserBarSlice,
    Peopledata:PeopleDataSlice,
    Homedata:HomeSlice,
    assetApi:AssetSlice,
    cmdbId:CMDBSlice,
    cloudCostApi:CostSlice,
    userAccessReviweApi:UserAccessReviewSlice,
    CBudgetApi:CBudgetSlice,
    SecurityHubApi:SecurityHubSlice,
    vulnerabilityApi : VulnerabilitiesSlice,
    PIIConfigurationSlice:PIIConfigurationSlice,
    appConfig:appConfigSlice,
    CisBenchmark:CISBenchmarkSlice,
    ICMApi:iCMSlice,
    TRMGSliceApi:TRMGSlice,
    Circular9Api:Circular9Slice,
    ICMCloudApi:iCMCloudSlice,
    EmailSchduleConfiguration:EmailScheduleConfigSlice,
    DataImportConfig:DataImportConfigSlice,
    HomeApiCall:HomeApiSlice,
    LicenseApiCall : LicenseSlice,
    reviewApi:reviewslice,
    AuditLogApi: AuditLogSlice,
    toolsApi : ToolsSlice,
    GroupApi : GroupSlice,
    OrganizationInformation : OrgInfoSlice,
    FlowMetricsApi:FlowMetricsSlice,
    filterApi : filterSlice,
    PeopleMetricsSlice: PeopleMetricsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(api.middleware),
});
