import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getDivisionList = createAsyncThunk(
    "post/getgruopCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(
                `/api/v1/organizationInfo/businessDivisionList/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
//count api
export const getDivisionCount = createAsyncThunk(
    "post/getgruopData",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(
                `/api/v1/organizationInfo/businessDivisionListCount`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
//save Api
export const getsaveDivision = createAsyncThunk(
    "post/saveDivision",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const  payload = props;
            const response = await fetch(
                `/api/v1/organizationInfo/saveOrUpdateBusinessDivision`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
// Delete Api
export const getDeleteDivision = createAsyncThunk(
    "post/DeleteDivision",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const  rowId = props;
            const response = await fetch(
                `/api/v1/organizationInfo/deleteBusinessDivision/${rowId}`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
);
// Division list api
export const getDivisionlistinput = createAsyncThunk(
    "get/DivisionList",
    async () => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(
                `/api/v1/organizationInfo/getBusineessDivisionList`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

//Department Api's

//Department List Api...
export const getDepartmentList = createAsyncThunk(
    "post/getdepartmentList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(
                `/api/v1/organizationInfo/businessDepartmentList/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

//Count Api
export const getDepartmentCount = createAsyncThunk(
    "post/getdepartmentcount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(
                `/api/v1/organizationInfo/businessDepartmentListCount`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
// Save Api...
export const getsaveDepartment = createAsyncThunk(
    "post/saveDepartment",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const  payload = props;
            const response = await fetch(
                `/api/v1/organizationInfo/saveOrUpdateBusinessDepartment`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
// Department Delete Api
export const getDeleteDepartment = createAsyncThunk(
    "post/DeleteDepartment",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const  rowId = props;
            const response = await fetch(
                `/api/v1/organizationInfo/deleteBusinessDepartment/${rowId}`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            throw error;
        }
    }
);

//Department input list Api
export const getDepartmentlistinput = createAsyncThunk(
    "get/DepartmentList",
    async () => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(
                `/api/v1/organizationInfo/getBusineessDepartmentList`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

//Department List Api
export const Userlistinput = createAsyncThunk(
    "get/UserList",
    async () => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(
                `/api/v1/licence/user/getDivisionHeadRoleUsers/details`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const UserlistinputDep = createAsyncThunk(
    "get/UserListDep",
    async () => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(
                `/api/v1/licence/user/getDepartmentHeadRoleUsers/details`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

const OrgInfoSlice = createSlice({
    name: "GroupSlice",
    initialState: {
        resetData: [],
        //Division State...
        DivisionData: [],
        DivisionCount: [],
        saveDivision:[],
        DeleteDivision:"",
        DivisionList:[],
        saveloading:false,
        Deleteloading:false,
        countLoading:false,
        DivisionLoading: false,
        //Department State...
        DepartmentData: [],
        DepartmentCount: [],
        saveDepartment:[],
        DeleteDepartment:"",
        DepartmentList:[],
        UserList:[],
        UserListDep: [],
        userloading:false,
        saveDeploading:false,
        DeleteDeploading:false,
        countDepLoading:false,
        DepartmentLoading: false,
        DepartmentListLoading: false,
        error: [],
        Deleteerror: "",
        DeleteDivisionerror: "",
    },
    reducers: {
        setOrgInfoSlice: {
            reducer(state) {
                state.Deleteerror = "";
                state.DeleteDivisionerror = "";
                state.DeleteDivision = "";
                state.DeleteDepartment = "";
            },
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getDivisionList.pending, (state, action) => {
                state.DivisionLoading = true;
            })
            .addCase(getDivisionList.fulfilled, (state, action) => {
                state.DivisionData = action.payload;
                state.DivisionLoading = false;
            })
            .addCase(getDivisionList.rejected, (state, action) => {
                state.DivisionLoading = false;
                state.DivisionData = [];
                state.error = "Something went wrong";
            })

            .addCase(getDivisionCount.pending, (state, action) => {
                state.countLoading = true;
            })
            .addCase(getDivisionCount.fulfilled, (state, action) => {
                state.DivisionCount = action.payload;
                state.countLoading = false;
            })
            .addCase(getDivisionCount.rejected, (state, action) => {
                state.countLoading = false;
                state.DivisionCount = [];
                state.error = "Something went wrong";
            })

            .addCase(getsaveDivision.pending, (state, action) => {
                state.saveloading = true;
            })
            .addCase(getsaveDivision.fulfilled, (state, action) => {
                state.saveDivision = action.payload;
                state.saveloading = false;
            })
            .addCase(getsaveDivision.rejected, (state, action) => {
                state.saveloading = false;
                state.saveDivision = [];
                state.error = "Something went wrong";
            })

            .addCase(getDeleteDivision.pending, (state, action) => {
                state.Deleteloading = true;
                state.DeleteDivision = '';
                state.DeleteDivisionerror='';
            })
            .addCase(getDeleteDivision.fulfilled, (state, action) => {
                state.DeleteDivision = action.payload;
                state.Deleteloading = false;
                state.DeleteDivisionerror='';
            })
            .addCase(getDeleteDivision.rejected, (state, action) => {
                state.Deleteloading = false;
                state.DeleteDivision = '';
                state.DeleteDivisionerror= action.error.message;
            })

            .addCase(getDivisionlistinput.pending, (state, action) => {
                state.Deleteloading = true;
            })
            .addCase(getDivisionlistinput.fulfilled, (state, action) => {
                state.DivisionList = action.payload;
                state.Deleteloading = false;
            })
            .addCase(getDivisionlistinput.rejected, (state, action) => {
                state.Deleteloading = false;
                state.DivisionList = [];
                state.error = "Something went wrong";
            })



            //Department api Reducers

            .addCase(getDepartmentList.pending, (state, action) => {
                state.DepartmentLoading = true;
            })
            .addCase(getDepartmentList.fulfilled, (state, action) => {
                state.DepartmentData = action.payload;
                state.DepartmentLoading = false;
            })
            .addCase(getDepartmentList.rejected, (state, action) => {
                state.DepartmentLoading = false;
                state.DepartmentData = [];
                state.error = "Something went wrong";
            })

            .addCase(getDepartmentCount.pending, (state, action) => {
                state.countDepLoading = true;
            })
            .addCase(getDepartmentCount.fulfilled, (state, action) => {
                state.DepartmentCount = action.payload;
                state.countDepLoading = false;
            })
            .addCase(getDepartmentCount.rejected, (state, action) => {
                state.countDepLoading = false;
                state.DepartmentCount = [];
                state.error = "Something went wrong";
            })

            .addCase(getsaveDepartment.pending, (state, action) => {
                state.saveDeploading = true;
            })
            .addCase(getsaveDepartment.fulfilled, (state, action) => {
                state.saveDepartment = action.payload;
                state.saveDeploading = false;
            })
            .addCase(getsaveDepartment.rejected, (state, action) => {
                state.saveDeploading = false;
                state.saveDepartment = [];
                state.error = "Something went wrong";
            })

            .addCase(getDeleteDepartment.pending, (state, action) => {
                state.DeleteDeploading = true;
                state.Deleteerror = "";
                state.DeleteDepartment = "";
            })
            .addCase(getDeleteDepartment.fulfilled, (state, action) => {
                state.DeleteDepartment = action.payload;
                state.DeleteDeploading = false;
                state.Deleteerror = "";
            })
            .addCase(getDeleteDepartment.rejected, (state, action) => {
                state.DeleteDeploading = false;
                state.DeleteDepartment = "";
                state.Deleteerror = action.error.message;
            })

            .addCase(getDepartmentlistinput.pending, (state, action) => {
                state.DepartmentListLoading = true; 
            })
            .addCase(getDepartmentlistinput.fulfilled, (state, action) => {
                state.DepartmentList = action.payload;
                state.DepartmentListLoading = false;
            })
            .addCase(getDepartmentlistinput.rejected, (state, action) => {
                state.DepartmentListLoading = false;
                state.DepartmentList = [];
                state.error = "Something went wrong";
            })

            .addCase(Userlistinput.pending, (state, action) => {
                state.userloading = true; 
            })
            .addCase(Userlistinput.fulfilled, (state, action) => {
                state.UserList = action.payload;
                state.userloading = false;
            })
            .addCase(Userlistinput.rejected, (state, action) => {
                state.userloading = false;
                state.UserList = [];
                state.error = "Something went wrong";
            })

            .addCase(UserlistinputDep.pending, (state, action) => {
                state.userloading = true; 
            })
            .addCase(UserlistinputDep.fulfilled, (state, action) => {
                state.UserListDep = action.payload;
                state.userloading = false;
            })
            .addCase(UserlistinputDep.rejected, (state, action) => {
                state.userloading = false;
                state.UserListDep = [];
                state.error = "Something went wrong";
            })
    },
});

export const { setOrgInfoSlice } = OrgInfoSlice.actions;

export default OrgInfoSlice.reducer;
