import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const graphtDataApi = createAsyncThunk(
  "post/GraphData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      console.log(props)
      const { payload, packName  } = props;
      const response = await fetch(
        `/api/v1/conformancePack/TRMG/GraphData/${packName}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

 
    const initialState= {
      graphtData: [],
      graphtDataLoading: false,
      error: "",
    }

export const Circular9Slice = createSlice({
    name: 'circular9Slice',
    initialState,

    reducers: {
        resetCircular9Data: () => {
            return initialState;
           }
    },    extraReducers: (builder) => {
      builder
        // C9 chart
        .addCase(graphtDataApi.pending, (state, action) => {
          state.graphtDataLoading = true;
         })
        .addCase(graphtDataApi.fulfilled, (state, action) => {
          state.graphtData = action.payload;
          state.graphtDataLoading = false;
        })
        .addCase(graphtDataApi.rejected, (state, action) => {
          state.graphtDataLoading = false;
        });

    },
});

export const {resetCircular9Data} = Circular9Slice.actions;
export default Circular9Slice.reducer;