import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoggedUser, setPageLoading } from "./app/commonSlice";
import Container from "./app/common-components/Container";
import ContainerLoader from "./app/common-components/ContainerLoader";
import { userRestrictedAccess } from "./app/utilities/constants";
import {setRedirectPath} from "./app/utilities/helpers";
import Cookies from "universal-cookie";

const PrivateRoute = (props) => {
  const location = useLocation();
  const pathName = get(location, "pathname", "");
  const { loggedUser, pageLoading } = useSelector((state) => state.common);
  const { userName, userRole ,token } = loggedUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkUserAccess = (userRole) => {
    if (
      document.title === "CODE8 - Access Denied" &&
      pathName !== "/access-denied"
    )
      document.title = "CODE8";
    if (userRole.trim() === "User") {
      const matchedPathIndex = userRestrictedAccess.indexOf(pathName);
      if (matchedPathIndex !== -1) {
        navigate("/access-denied");
        return false;
      }
    }
    pathName === "/" && navigate("/home");
    dispatch(setPageLoading(false));
  };

  const getLoggedUser = () => {
    const cookies = new Cookies();
    fetch("/getTopHeader", {
      method: "GET",
      redirect: "manual",
      cache: "no-store",
    })
      .then(async (data) => {
        setRedirectPath();
        if (data.status === 200) {
          const html = await data.text();
          const htmlDoc = new DOMParser().parseFromString(html, "text/html");
          const userName = htmlDoc.getElementsByTagName("h4")[0].textContent;
          const userRole = htmlDoc.getElementsByTagName("h6")[0].textContent;
          const token = htmlDoc.getElementsByClassName("token")[0].textContent;
          dispatch(setLoggedUser({userName,userRole,token}));
          sessionStorage.setItem("isLoggedin",true)
          cookies.set("apitoken", token, { path: "/"});
          cookies.set("username", userName, { path: "/"});
          return;
        } else {
          sessionStorage.setItem("lastVistedURL", pathName);
          sessionStorage.setItem("isLoggedin",false);
          navigate("/auth");
        }
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  useEffect(() => {
    getLoggedUser();
  }, []);
  useEffect(() => {
    if (pathName === "/access-denied") document.title = "CODE8 - Access Denied";
    userName !== "" && userRole !== "" && token !== "" && checkUserAccess(userRole);
  }, [pathName, userName, userRole, token, pageLoading]);

  return <>{pageLoading ? <ContainerLoader /> : <Container />}</>;
};

export default PrivateRoute;
