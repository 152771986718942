import React from "react";
import {
  observability,
  gnc,
  efficiency,
  configuration,
  house,
} from "../assets/images/index";

const SignIn = React.lazy(() => import("../features/auth/SignIn"));
const HomePage = React.lazy(() =>
  import("../features/homePage/HomePageLanding.js")
);

const FlowMetrics = React.lazy(() =>
  import("../features/observability/flowMetrics/FlowMetricsLanding")
);
const PeopleMetrics = React.lazy(() =>
  import("../features/observability/PeopleMetricsObs/Landing")
);
const ProductivityMetrics = React.lazy(() =>
  import(
    "../features/observability/productivityMetrics/ProductivityMetricsLanding"
  )
);
const Manageopsmetrics = React.lazy(() =>
  import("../features/observability/opsMetrics/Manageopsmetrics")
);
const CloudCostInsights = React.lazy(() =>
  import("../features/gnc/CloudCostInsights/Landing.js")
);
const LicenseManagement = React.lazy(() =>
  import("../features/gnc/LicenseManagement/LincenseLanding")
);

const AdminActivity = React.lazy(() =>
  import("../features/gnc/adminActivity/AdminActivityLanding")
);
const Pii = React.lazy(() => import("../features/gnc/Pii/PiiLandingPage"));
const Vulnerabilites = React.lazy(() =>
  import("../features/gnc/Vulnerabilites/VulnerabilitesLanding.js")
);
const CISBenchmarkCompliance = React.lazy(() =>
  import("../features/gnc/CISBenchmarkCompliance/CISBenchmarkComplianceLanding")
);
const CisoDBoardManagement = React.lazy(() =>
  import("../features/gnc/cisoDashboard/CisoLandingPage.js")
);
const CisoSHFLanding = React.lazy(() =>
  import("../features/gnc/cisoDashboard/CisoSHFLanding.js")
);
const CisoTRMGLanding = React.lazy(() =>
  import("../features/gnc/cisoDashboard/CisoTRMGLanding.js")
);
const CisoLandingCircular09 = React.lazy(() =>
  import("../features/gnc/cisoDashboard/CisoLandingCircular09.js")
);
const CisoLandingCIS = React.lazy(() =>
  import("../features/gnc/cisoDashboard/CisoLandingCIS.js")
);

const GNCLanding = React.lazy(() =>
  import("../features/gnc/Summary/GNCLanding.js")
);
const TRMGLanding = React.lazy(() =>
  import("../features/gnc/TRMG/TRMGLanding.js")
);
const CircularLanding = React.lazy(() =>
  import("../features/gnc/circular9/CircularLanding.js")
);
const SecurityHubFindings = React.lazy(() =>
  import(
    "../features/gnc/complianceCheck/securityHub/securityHubFindingsLandings"
  )
);
const ComplianceManagement = React.lazy(() =>
  import("../features/gnc/complianceCheck/ComplianeceManage")
);

// const ComplianceManagementNormal = React.lazy(() =>
//   import("../features/gnc/complianceCheck/complianceManagement/complianceManagementLanding")
// );
// const CloudComplianceManagement = React.lazy(() =>
//   import("../features/gnc/complianceCheck/ComplianceManagementCloud/IcmCloudLanding")
// );
const UserAccessManagement = React.lazy(() =>
  import("../features/gnc/CisoUserAccess/cisouseraccesslanding")
);
// let currentRole = '';
// export const UserRoleForNavBar = (userRole) => {
// console.log(userRole);
// currentRole = userRole;
// };

// console.log(currentRole);

// compliance Landing

const AppConfig = React.lazy(() =>
  import("../features/efficiency/appConfig/AppConfig")
);
const AppConfigProjects = React.lazy(() =>
  import("../features/efficiency/appConfig/Projects")
);

const AppConfigServers = React.lazy(() =>
  import("../features/efficiency/appConfig/Servers")
);
const LicenseConfigpage = React.lazy(() =>
  import("../features/configuration/cmdb/licenseconfig")
);
const NewProvisionpage = React.lazy(() =>
  import("../features/configuration/cmdb/newProvision")
);
const ApplicationConfiguration = React.lazy(() =>
  import("../features/configuration/cmdb/CmdbApplicationConfiguration")
);
const AppConfigUsers = React.lazy(() =>
  import("../features/efficiency/appConfig/users")
);
const AppConfigAccountId = React.lazy(() =>
  import("../features/efficiency/appConfig/accountIds")
);
const OnBoardingTools = React.lazy(() =>
  import("../features/efficiency/OnBoarding/OnBoardingTools")
);
const AccessManagement = React.lazy(() =>
  import("../features/efficiency/accessManagement/AccessManagement")
);
const MyRequest = React.lazy(() =>
  import("../features/efficiency/myRequest/MyRequest")
);
const MyApproval = React.lazy(() =>
  import("../features/efficiency/myApproval/MyApproval")
);
const AuditLog = React.lazy(() =>
  import("../features/efficiency/auditLog/AuditLog")
);

const Tools = React.lazy(() =>
  import("../features/configuration/settings/tools/Tools")
);

const Business = React.lazy(() =>
  import("../features/configuration/cmdb/BusinessInfoConfig")
);

const OrganizationInfo = React.lazy(() =>
  import(
    "../features/configuration/cmdb/Organizational-Info/OrganizationalInfo"
  )
);

const User = React.lazy(() =>
  import("../features/configuration/cmdb/UserInfoConfig")
);

const Serverconfig = React.lazy(() =>
  import("../features/configuration/cmdb/Serverconfig")
);
const Cloudaccount = React.lazy(() =>
  import("../features/configuration/cmdb/cloudaccounttable/cloudaccount")
);
//Tools coonfiguration Roles
const BambooRoles = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/BambooRoles"
  )
);
const BitBucketRoles = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/BitBucketRoles"
  )
);
const ConfluenceCloudRole = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/ConfluenceCloudRole"
  )
);
const JFrogPermission = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/JFrogPermission"
  )
);
const JenkinRoles = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/JenkinRoles"
  )
);
const JiraCloudRoles = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/JiraCloudRoles"
  )
);
const JiraRoles = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/JiraRoles"
  )
);
const NexusRoles = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/NexusRoles"
  )
);
const SonarQubeRoles = React.lazy(() =>
  import(
    "../features/configuration/settings/tools/configurationRoles/SonarQubeRoles"
  )
);
const Groups = React.lazy(() =>
  import("../features/configuration/settings/groups/Groups")
);
const PIIDataConfiguration = React.lazy(() =>
  import(
    "../features/configuration/settings/PIIConfiguration/PIIConfigurationContainer"
  )
);

// const EmailScheduleContainer = React.lazy(() =>
//   import(
//     "../features/configuration/settings/EmailScheduleConfiguration/EmailScheduleContainer"
//   )
// );
// const DataImportScheduler = React.lazy(() =>
//   import(
//     "../features/configuration/settings/DataImportScheduler/DataImportLanding"
//   )
// );
const Schedulers = React.lazy(() =>
  import(
    "../features/configuration/settings/Schedulers/ManageSchedulers.js"
  )
);
const LdapConfig = React.lazy(() =>
  import("../features/configuration/admin/ldapConfig/LdapConfig")
);
const SmtpConfig = React.lazy(() =>
  import("../features/configuration/admin/smtpConfig/SmtpConfig")
);
const LicenseConfig = React.lazy(() =>
  import("../features/configuration/admin/licenseConfig/LicenseConfig")
);
const UserManagement = React.lazy(() =>
  import("../features/configuration/admin/userManagement/UserManagement")
);
const LoggingConfig = React.lazy(() =>
  import("../features/configuration/admin/loggingConfig/LoggingConfig")
);
const CISCompliance = React.lazy(() =>
  import("../features/gnc/CISCompliance/CISLandingPage")
);

const DataGovernance = React.lazy(() => 
  import("../features/gnc/dataGovernance/dataGovernance")
)

// const ListForRiskAssessment = React.lazy(() =>
//   import("../features/configuration/riskAssessment/listForStepOne")
// );
const UserAccessReview = React.lazy(() =>
  import("../features/homePage/ApplicationUserAccessReview/userReviewLanding")
);
// const UserAssessReviewTable = React.lazy(() =>
//   import("../features/gnc/UserAccessReview/UserAccessReview.js")
// );

export const authRoutes = [
  {
    name: "SignIn",
    path: "/logout",
    component: <SignIn />,
    key: 0,
  },
  {
    name: "SignIn",
    path: "/auth",
    component: <SignIn />,
    key: 0,
  },
];

export const routes = [
  //CODE8 Home page
  {
    name: "Home",
    path: "home",
    component: <HomePage />,
    key: "5",
    icon: house,
    iconNavigation: true,
    childNavs: [
      {
        name: "Home",
        text: "Home",
        path: "home",
        component: <HomePage />,
        key: "5.1",
        navigationDisable: true,
        isNotSideBarNavigation: true,
      },
      // ]

      {
        name: "Application User Access Review",
        path: "home/userReview",
        component: <UserAccessReview />,
        key: "5.2",
        isNotSideBarNavigation: true,
      },
      {
        name: "Business Info",
        path: "home/BusinessInfoConfig",
        component: <Business />,
        key: "5.3",
        isNotSideBarNavigation: true,
      },
      {
        name: "Devices",
        path: "home/Devices",
        component: <Serverconfig />,
        key: "5.4",
        isNotSideBarNavigation: true,
      },
      {
        name: "Cloud Accounts",
        path: "home/cloudaccount",
        component: <Cloudaccount />,
        key: "5.5",
        isNotSideBarNavigation: true,
      },
      {
        name: "Identities",
        path: "home/UserInfoConfig",
        component: <User />,
        key: "5.6",
        isNotSideBarNavigation: true,
      },
    ],
  },

  //CODE8 Observability Observability Name changed to DevOps Insights
  {
    name: "DevOps Insights",
    path: "observability",
    component: <FlowMetrics />,
    key: "1",
    icon: observability,
    childNavs: [
      {
        text: "Observability",
        name: "observability",
        path: "observability/flowMetrics",
        navigationDisable: true,
        component: <FlowMetrics />,
        key: "1.1",
        childNavs: [
          {
            name: "FlowMetrics",
            text: "Flow Metrics",
            path: "observability/flowMetrics",
            component: <FlowMetrics />,
            key: "1.1.1",
          },
          {
            name: "PeopleMetrics",
            text: "People Metrics",
            path: "observability/peopleMetrics",
            component: <PeopleMetrics />,
            key: "1.1.3",
          },
          {
            text: "Productivity Metrics",
            name: "ProductivityMetrics",
            path: "observability/productivityMetrics",
            component: <ProductivityMetrics />,
            key: "1.1.4",
          },
          {
            text: "Ops Metrics",
            name: "OpsMetrics",
            path: "observability/opsMetrics",
            component: <Manageopsmetrics />,
            key: "1.1.6",
          },
        ],
      },
      {
        text: "Efficiency",
        name: "Efficiency",
        path: "observability/efficiency/appConfig",
        navigationDisable: true,
        customDropContainerClass: "efficiency",
        key: "1.2",
        childNavs: [
          {
            name: "AppConfig",
            text: "App Config",
            path: "observability/efficiency/appConfig",
            component: <AppConfig page="efficiency" />,
            key: "1.2.1",
          },
          {
            name: "AccessManagement",
            text: "Access Management",
            path: "observability/efficiency/accessManagement",
            component: <AccessManagement />,
            key: "1.2.2",
          },
          {
            name: "MyRequest",
            text: "My Request",
            path: "observability/efficiency/myRequest",
            component: <MyRequest />,
            key: "1.2.3",
          },
          {
            name: "MyApproval",
            text: "My Approval",
            path: "observability/efficiency/myApproval",
            component: <MyApproval />,
            key: "1.2.4",
          },
          // {
          //   name: "AuditLog",
          //   text: "Audit Log",
          //   path: "observability/efficiency/auditLog",
          //   component: <AuditLog />,
          //   key: "1.2.5",
          // },
          {
            key: "1.2.6",
            name: "Project",
            path: "observability/efficiency/appConfig/:appname/project",
            isNotSideBarNavigation: true,
            component: <AppConfigProjects />,
          },
          {
            key: "1.2.7",
            name: "Onboarding Tools",
            path: "observability/efficiency/onboardingtools",
            isNotSideBarNavigation: true,
            component: <OnBoardingTools />,
          },
          {
            key: "1.2.8",
            name: "Server",
            path: "observability/efficiency/appConfig/:appname/Devices",
            isNotSideBarNavigation: true,
            component: <AppConfigServers />,
          },
          {
            key: "1.2.9",
            name: "Users",
            path: "observability/efficiency/appConfig/:appname/users",
            isNotSideBarNavigation: true,
            component: <AppConfigUsers />,
          },
          {
            key: "1.2.10",
            name: "Account IDs",
            path: "observability/efficiency/appConfig/:appname/accountIDs",
            isNotSideBarNavigation: true,
            component: <AppConfigAccountId />,
          },
        ],
      }
    ],
  },
  //CODE8 Governance and Compliance
  {
    name: "Governance and Compliance",
    path: "gnc",
    component: <LicenseManagement />,
    key: "2",
    icon: gnc,
    //iconNavigation:true,
    toolTipCustomClass: "hovertipgnc",
    childNavs: [
      {
        name: "Compliance",
        text: "Compliance",
        path: "gnc/Compliance/Summary",
        component: <GNCLanding />,
        navigationDisable: false,
        key: "2.2",
        childNavs: [
          {
            name: "Vulnerability Management",
            text: "Vulnerability Management",
            path: "gnc/compliance/Vulnerability",
            component: <Vulnerabilites />,
            key: "2.2.1",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/Vulnerability",
            component: <CisoDBoardManagement />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/securityHubFindings",
            component: <CisoSHFLanding />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/trmgCompliance",
            component: <CisoTRMGLanding />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/circular09Compliance",
            component: <CisoLandingCircular09 />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/CISBenchmarkCompliance",
            component: <CisoLandingCIS />,
            key: "2.2.2",
          },
          {
            name: "TRMG Compliance",
            text: "TRMG Compliance",
            path: "gnc/compliance/trmgCompliance",
            component: <TRMGLanding />,
            key: "2.2.4",
          },
          {
            name: "Circular 09 Compliance",
            text: "Circular 09 Compliance",
            path: "gnc/compliance/circular09Compliance",
            component: <CircularLanding />,
            key: "2.2.4",
          },

          {
            name: "Security Hub Findings",
            text: "Security Hub Findings",
            path: "gnc/compliance/securityHub/securityHubFindingsLandings",
            component: <SecurityHubFindings />,
            key: "2.2.5",
          },
          {
            name: "Identity Compliance Management",
            text: "Identity Compliance Management",
            path: "gnc/compliance/identityComplianceManagement",
            component: <ComplianceManagement />,
            key: "2.2.6",
          },
          {
            name: "Vulnerability Management",
            text: "Vulnerability Management",
            path: "gnc/compliance/CISBenchmarkCompliance",
            component: <CISBenchmarkCompliance />,
            key: "2.2.7",
          },
          {
            name: "Compliance",
            text: "Compliance",
            path: "gnc/Compliance/Summary",
            component: <GNCLanding />,
            key: "2.2.8",
          },
          {
            name: "PII Data",
            text: "PII Data",
            path: "gnc/compliance/PII",
            component: <Pii />,
            key: "2.2.9",
          },
          {
            name: "CIS Compliance",
            text: "CIS Compliance",
            path: "gnc/compliance/CISCompliance",
            component: <CISCompliance />,
            key: "2.2.11",
          },
          {
            name: "Data Governance",
            text: "Data Governance",
            path: "/gnc/Compliance/dataGovernance",
            component: <DataGovernance />,
            key: "2.2.12"
          }
          // {
          //   name: "User Access Review",
          //   text: "User Access Review",
          //   path: "gnc/UserAccessReview",
          //   component: <UserAssessReviewTable />,
          //   key: "2.2.18",
          // }
        ],
      },
      {
        name: "Ciso Insights",
        text: "CISO Insights",
        path: "gnc/CISOInsights/Vulnerability",
        component: <CisoDBoardManagement />,
        key: "2.3",
      },
      {
        name: "Cloud Cost Insights",
        text: "Cloud Cost Insights",
        //isNotSideBarNavigation: true,
        path: "gnc/CloudCostInsights",
        component: <CloudCostInsights />,
        key: "2.4",
      },

      {
        name: "gnc",
        text: "License Management",
        //isNotSideBarNavigation: true,
        path: "gnc/licenseManagement",
        component: <LicenseManagement />,
        key: "2.5",
      },
      {
        name: "AdminActivity",
        text: "Admin Activity",
        path: "gnc/AdminActivity",
        component: <AdminActivity />,
        key: "2.6",
      },
      {
        name: "User Access Review",
        text: "User Access Review",
        isNotSideBarNavigation: true,
        path: "gnc/Compliance/userAccessReview",
        component: <UserAccessManagement />,
        key: "2.7",
      },
    ],
  },
  //CODE8 Efficiency
  // {
  //   name: "Efficiency",
  //   path: "efficiency",
  //   component: <AppConfig page="efficiency" />,
  //   key: "3",
  //   icon: efficiency,
  //   childNavs: [
  //     {
  //       name: "AppConfig",
  //       text: "App Config",
  //       path: "efficiency/appConfig",
  //       component: <AppConfig page="efficiency" />,
  //       key: "3.1",
  //     },
  //     {
  //       name: "AccessManagement",
  //       text: "Access Management",
  //       path: "efficiency/accessManagement",
  //       component: <AccessManagement />,
  //       key: "3.2",
  //     },
  //     {
  //       name: "MyRequest",
  //       text: "My Request",
  //       path: "efficiency/myRequest",
  //       component: <MyRequest />,
  //       key: "3.3",
  //     },
  //     {
  //       name: "MyApproval",
  //       text: "My Approval",
  //       path: "efficiency/myApproval",
  //       component: <MyApproval />,
  //       key: "3.4",
  //     },
  //     {
  //       name: "AuditLog",
  //       text: "Audit Log",
  //       path: "efficiency/auditLog",
  //       component: <AuditLog />,
  //       key: "3.5",
  //     },
  //     {
  //       key: "3.6",
  //       name: "Project",
  //       path: "efficiency/:appname/project",
  //       isNotSideBarNavigation: true,
  //       component: <AppConfigProjects />,
  //     },
  //     {
  //       key: "3.7",
  //       name: "Onboarding Tools",
  //       path: "efficiency/onboarding",
  //       isNotSideBarNavigation: true,
  //       component: <OnBoardingTools />,
  //     },
  //     {
  //       key: "3.8",
  //       name: "Server",
  //       path: "efficiency/:appname/Devices",
  //       isNotSideBarNavigation: true,
  //       component: <AppConfigServers />,
  //     },
  //     {
  //       key: "3.9",
  //       name: "Users",
  //       path: "efficiency/:appname/users",
  //       isNotSideBarNavigation: true,
  //       component: <AppConfigUsers />,
  //     },
  //     {
  //       key: "3.10",
  //       name: "Account IDs",
  //       path: "efficiency/:appname/accountIDs",
  //       isNotSideBarNavigation: true,
  //       component: <AppConfigAccountId />,
  //     },
  //   ],
  // },

  //CODE8 Configuration
  {

    name: "Configuration",
    path: "configuration",
    component: <Tools />,
    key: "4",
    icon: configuration,
    childNavs: [{
      name: "CMDB",
      text: "CMDB",
      path: "configuration/cmdb",
      //navigationDisable: true,
      customDropContainerClass: "CMDB",
      component: <AppConfig page="configuration" />,
      key: "4.11",
      childNavs: [
        {
          name: "Business Info",
          text: "Business Info",
          path: "configuration/cmdb/BusinessInfoConfig",
          component: <Business />,
          key: "4.11.1",
        },
        {
          name: "Role Allocation",
          text: "Role Allocation",
          path: "configuration/cmdb/BusinessInfoConfig/roleAllocation",
          component: <OrganizationInfo />,
          key: "4.11.2",
          isNotSideBarNavigation: true,
        },

        {
          name: "Users",
          text: "Users",
          path: "configuration/cmdb/UserInfoConfig",
          component: <User />,
          key: "4.11.3",
        },

        {
          name: "Devices",
          text: "Devices",
          path: "configuration/cmdb/Devices",
          component: <Serverconfig />,
          key: "4.11.4",
        },
        {
          name: "Cloud Accounts",
          text: "Cloud Accounts",
          path: "configuration/cmdb/cloudaccounttable/cloudaccount",
          component: <Cloudaccount />,
          key: "4.11.5",
        },
        {
          name: "AppConfig",
          // text: "App Config",
          path: "configuration/cmdb",
          isNotSideBarNavigation: true,
          component: <AppConfig page="configuration" />,
          key: "4.11.6",
        },
        {
          key: "4.11.7",
          name: "Server",
          path: "configuration/cmdb/:appName/DeviceConfig",
          isNotSideBarNavigation: true,
          component: <AppConfigServers />,
        },
        {
          key: "4.11.8",
          name: "Users",
          path: "configuration/cmdb/:appName/users",
          isNotSideBarNavigation: true,
          component: <AppConfigUsers />,
        },
        {
          key: "4.11.9",
          name: "Account IDs",
          path: "configuration/cmdb/:appName/accountIDs",
          isNotSideBarNavigation: true,
          component: <AppConfigAccountId />,
        },
        {
          key: "4.11.11",
          name: "Application Configuration",
          text: "Application Configuration",
          path: "configuration/cmdb/applicationConfiguration",
          isNotSideBarNavigation: true,
          component: <ApplicationConfiguration />,
        },
      ],
    },

    {
      name: "SonarQube Roles",
      path: "configuration/settings/tools/sonarqubeRole",
      isNotSideBarNavigation: true,
      component: <SonarQubeRoles />,
      key: "5.0",
    },
    {
      key: "6.0",
      name: "License Config",
      path: "configuration/cmdb/Devices/licenseconfigpage",
      isNotSideBarNavigation: true,
      component: <LicenseConfigpage />,
    },
    {
      key: "7.0",
      name: "Infra Provisioning",
      path: "configuration/cmdb/Devices/newProvisionpage",
      isNotSideBarNavigation: true,
      component: <NewProvisionpage />,
    },
    {
      name: "Settings",
      text: "Settings",
      path: "configuration/settings/tools",
      navigationDisable: true,
      customDropContainerClass: "settings",
      component: <Tools />,
      key: "4.1",
      childNavs: [
        {
          name: "Schedulers",
          text: "Schedulers",
          path: "configuration/settings/Schedulers",
          component: <Schedulers />,
          key: "4.1.1",
        },
        {
          name: "Tools",
          text: "Tools",
          path: "configuration/settings/tools",
          component: <Tools />,
          key: "4.1.2",
        },
        {
          name: "Groups",
          text: "Groups",
          path: "configuration/settings/groups",
          component: <Groups />,
          key: "4.1.3",
        },
        {
          name: "PII Data Configuration",
          text: "PII Data Configuration",
          path: "configuration/settings/PIIDataConfiguration",
          component: <PIIDataConfiguration />,
          key: "4.1.4",
        },
        // {
        //   name: "Email Schedule Configuration",
        //   text: "Email Schedule Configuration",
        //   path: "configuration/settings/EmailScheduleConfiguration",
        //   component: <EmailScheduleContainer />,
        //   key: "4.1.4",
        // },

      ],
    },
    {
      name: "Administration",
      text: "Administration",
      path: "configuration/administration/ldapConfig",
      navigationDisable: true,
      customDropContainerClass: "admin",
      component: <LdapConfig />,
      key: "4.2",
      childNavs: [
        {
          name: "LDAP Configuration",
          text: "LDAP Configuration",
          path: "configuration/administration/ldapConfig",
          component: <LdapConfig />,
          key: "4.2.1",
        },
        {
          name: "SmtpConfig",
          text: "SMTP Configuration",
          path: "configuration/administration/smtpConfig",
          component: <SmtpConfig />,
          key: "4.2.2",
        },
        {
          name: "LicenseConfig",
          text: "License Configuration",
          path: "configuration/administration/licenseConfig",
          component: <LicenseConfig />,
          key: "4.2.3",
        },
        {
          name: "UserManagement",
          text: "User Management",
          path: "configuration/administration/userManagement",
          component: <UserManagement />,
          key: "4.2.4",
        },
        {
          name: "LoggingConfig",
          text: "Logging Configuration",
          path: "configuration/administration/loggingConfig",
          component: <LoggingConfig />,
          key: "4.2.5",
        },

      ],
    },
    {
      name: "Bamboo Roles",
      path: "configuration/settings/tools/bambooRole",
      isNotSideBarNavigation: true,
      component: <BambooRoles />,
      key: "4.3",
    },
    {
      name: "Bitbucket Roles",
      path: "configuration/settings/tools/bitbucketRole",
      isNotSideBarNavigation: true,
      component: <BitBucketRoles />,
      key: "4.4",
    },
    {
      name: "Jenkins Roles",
      path: "configuration/settings/tools/jenkinsRole",
      isNotSideBarNavigation: true,
      component: <JenkinRoles />,
      key: "4.5",
    },
    {
      name: "Nexus Roles",
      path: "configuration/settings/tools/nexusRole",
      isNotSideBarNavigation: true,
      component: <NexusRoles />,
      key: "4.6",
    },
    {
      name: "JFrog Permission",
      path: "configuration/settings/tools/jfrogRole",
      isNotSideBarNavigation: true,
      component: <JFrogPermission />,
      key: "4.7",
    },
    {
      name: "Jira Roles",
      path: "configuration/settings/tools/jiraRole",
      isNotSideBarNavigation: true,
      component: <JiraRoles />,
      key: "4.8",
    },
    {
      name: "Jira Cloud Roles",
      path: "configuration/settings/tools/jiracloudRole",
      isNotSideBarNavigation: true,
      component: <JiraCloudRoles />,
      key: "4.9",
    },
    {
      name: "Confluence Cloud Roles",
      path: "configuration/settings/tools/confluencecloudRole",
      isNotSideBarNavigation: true,
      component: <ConfluenceCloudRole />,
      key: "4.9",
    },
    {
      name: "gnc",
      text: "License Management",
      isNotSideBarNavigation: true,
      path: "/gnc/licenseManagement/licenseconfigpage",
      component: <LicenseConfigpage />,
      key: "2.5",
    },
    {
      name: "AuditLog",
      text: "Audit Log",
      path: "configuration/administration/auditLog",
      component: <AuditLog />,
      key: "4.2.6",
    },


    ],

  },


];
