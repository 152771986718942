import { createSlice } from "@reduxjs/toolkit";

const efficiencySlice = createSlice({
  name: "efficiency",
  initialState: {
    appConfig: {
      tableData: [],
      projectTableData: [],
      serverTableData:[],
      appNameCode: [],
      managerList: [],
      filterData: {
        appCode: "",
        applicationName: "",
        primaryManager: "",
        secondaryManager: "",
        businessUnit: "",
        approvalGate: 0,
      },
    },
    accessManagement: {
      appDetails: {},
      userDetails: [],
      groupDetails: [],
      onBoardData: [],
      groupMembersData: [],
      groupPayload: {},
    },
    onBoarding: {
      toolsList: [],
      appDetails: {},
      projectLists: [],
      toolsVersions: {},
      onBoardForm: {},
      onboardInputList: {},
      onBoardSelected: {},
      activeToolName: "",
      codeActiveToolName: "",
      scanActiveToolName: "",
      packageActiveToolName: "",
      onBoardingType: "DevOpsTools",
      applyTemplate: false,
    },
    myRequest: {
      selectedDate: {},
      filterData: {},
      appList:[],
      managerList:[]
    },
  },
  reducers: {
    setAppConfig: {
      reducer(state, action) {
        state.appConfig = action.payload;
      },
      prepare(filterData, changedData) {
        return { payload: { ...filterData, ...changedData } };
      },
    },
    setAccessManagement: {
      reducer(state, action) {
        state.accessManagement = action.payload;
      },
      prepare(filterData, changedData) {
        return { payload: { ...filterData, ...changedData } };
      },
    },
    setOnboarding: {
      reducer(state, action) {
        state.onBoarding = action.payload;
      },
      prepare(filterData, changedData) {
        return { payload: { ...filterData, ...changedData } };
      },
    },
    setMyRequestFilterData: {
      reducer(state, action) {
        state.myRequest = action.payload;
      },
      prepare(filterData, changedData) {
        return { payload: { ...filterData, ...changedData } };
      },
    },
  },
});

export const {
  setAppConfig,
  setAccessManagement,
  setOnboarding,
  setMyRequestFilterData,
} = efficiencySlice.actions;

export default efficiencySlice.reducer;
